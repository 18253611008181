namespace aq.ui {

    import ActivityContextType = aq.models.activity.ActivityContextType;
    import ActivityFilter = aq.models.activity.ActivityFilter;
    import ActivityType = aq.models.activity.ActivityType;
    import GroupedActivities = aq.models.activity.GroupedActivities;
    import ProductCategory = aq.models.activity.ProductCategory;
    import SimpleActivityResponse = aq.models.activity.SimpleActivityResponse;
    import IComponent = aq.models.IComponent;
    import ActivityActions = aq.models.segment.ActivityActions;

    export enum ActivityMode {
        LIST = 'LIST',
        CARD = 'CARD'
    };

    interface IBindings {
        title?: string;
        accountId: number;
        buildingId: number;
        mode?: ActivityMode;
    }

    // Reading on component lifecycle hooks https://toddmotto.com/angular-1-5-lifecycle-hooks
    class _ActivityFeed implements IBindings, IComponent {
        public title: string = 'Activities';
        public accountId: number;
        public buildingId: number;
        public mode = ActivityMode.LIST;

        private filter: ActivityFilter = {
            range: {
                start: moment().subtract({ months: 1 }).startOf('day'),
                end: moment().endOf('day')
            },
            ytdRange: {
                start: moment().subtract({ months: 1 }).startOf('day'),
                end: moment().endOf('day')
            },
            types: {
                [ActivityContextType.ALERT]: true,
                [ActivityContextType.AID_ISSUE]: true,
                [ActivityContextType.ENERGY_NOTE]: true,
                [ActivityContextType.OPTIMAL_START]: true,
                [ActivityContextType.RX]: true,
                [ActivityContextType.NONE]: true
            }
        };
        private activities: SimpleActivityResponse[];
        private annualizedSavings: aq.models.activity.SavingsByAidType;
        private defaults = {
            start: moment().subtract({ months: 6 }).startOf('month').startOf('day'),
            end: moment().endOf('day')
        };
        private isLoadingNewBuildingData: boolean = true;
        private hasActivityResults: boolean = false;
        private exportingActivities: boolean = false;
        private activitiesByType: GroupedActivities;
        private building: aq.common.models.Building;
        private isAqAdmin: boolean;
        private isFilterMenuOpen: boolean;

        /* @ngInject */
        constructor(
            private $scope: ng.IScope,
            private resolver: aq.services.Resolver,
            private Auth,
            private RestangularV3: restangular.IService,
            private BuildingSelectorActions: aq.services.BuildingSelectorActions,
            private ModelUtilService: aq.services.ModelUtilService,
            private ActivityService: aq.services.ActivityService,
            private PrescriptionService: aq.services.PrescriptionService,
            private Segment: aq.services.SegmentService,
            private UserService: aq.services.UserService,
            private Messages: aq.services.Messages
        ) { }

        $onInit(): void {
            this.isAqAdmin = this.UserService.isAdmin();
            this.queryActivities(this.filter);
        }

        $postLink(): void { }

        $onDestroy(): void { }

        $onChanges(changes): void {
            if (changes.title) {
                this.title = changes.title.currentValue;
            }
            if (changes.buildingId) {
                this.isLoadingNewBuildingData = true;
                this.buildingId = changes.buildingId.currentValue;
                this.queryActivities(this.filter);
            }
            if (changes.accountId) {
                this.isLoadingNewBuildingData = true;
                this.accountId = changes.accountId.currentValue;
                this.buildingId = changes.buildingId.currentValue;
                this.queryActivities(this.filter);
            }
        }

        public toggleFilterMenu() {
            this.isFilterMenuOpen = !this.isFilterMenuOpen;
        }


        private toggleMode() {
            switch (this.mode) {
                case ActivityMode.CARD:
                    this.mode = ActivityMode.LIST;
                    this.queryActivities(this.filter);
                    this.activitiesByType = this.groupActivities(this.activities, this.filter);
                    break;
                case ActivityMode.LIST:
                    this.mode = ActivityMode.CARD;
                    this.queryActivities(this.filter);
                    this.activitiesByType = this.groupActivities(this.activities, this.filter);
                    break;
                default:
                    this.mode = ActivityMode.LIST;
            }
        }

        private includeUrgent = (activity: SimpleActivityResponse): boolean => {
            if (activity.type === ActivityType.ISSUE) {
                const { context } = activity;
                const { acknowledgedStatus, contextObject, type } = context;
                if (ActivityContextType.ALERT === type && acknowledgedStatus === 'OPEN') {
                    return true;
                }
                if (ActivityContextType.RX === type
                    && contextObject
                    && this.PrescriptionService.isUrgent(<aq.models.prescription.Case>contextObject)
                ) {
                    return true;
                }
            }
            return false;
        }

        private getIcon(category: ProductCategory): string {
            const prefix = 'aq-icons-product-';
            switch (category) {
                case aq.models.activity.ProductCategory.HVAC_ANALYTICS:
                    return `${prefix}hvac-analytics green`;
                case aq.models.activity.ProductCategory.LOAD_ANALYTICS:
                    return `${prefix}load-analytics orange`;
                case aq.models.activity.ProductCategory.NOTE:
                    return `${prefix}notes purple`;
                case aq.models.activity.ProductCategory.OPTIMIZATION_STRATEGY:
                    return `${prefix}optimization-strategy blue`;
                case aq.models.activity.ProductCategory.ALERT:
                    return `${prefix}alerts deep-blue`;
                default:
                    return '';
            }
        }

        private getStartDate(filter: ActivityFilter): moment.Moment {
            if (filter.range.start.isBefore(this.defaults.start)) {
                return filter.range.start;
            }
            return this.defaults.start;
        }

        private getEndDate(): moment.Moment {
            return this.defaults.end;
        }

        private queryActivities(filter: ActivityFilter): void {
            this.resolver(async () => {
                try {
                    this.isLoadingNewBuildingData = true;
                    if (!this.building || Number(this.building.id) !== this.buildingId) {
                        this.building = await this.RestangularV3.one('buildings', this.buildingId).get();
                    }
                    const now = new Date();
                    const startYear = now.getMonth() < this.building.fiscalStartMonth ? now.getFullYear() - 1 : now.getFullYear();
                    let fiscalStartMonth: string;
                    if (!this.building.fiscalStartMonth) {
                        fiscalStartMonth = '01';
                    } else if (this.building.fiscalStartMonth < 10) {
                        fiscalStartMonth = `0${this.building.fiscalStartMonth}`;
                    } else {
                        fiscalStartMonth = this.building.fiscalStartMonth.toString();
                    }

                    this.filter.ytdRange = {
                        start: moment(`${fiscalStartMonth}-01-${startYear}`, 'MM-DD-YYYY'),
                        end: moment().endOf('day')
                    };

                    const start = this.mode === ActivityMode.LIST ? this.getStartDate(filter).toISOString() : this.filter.ytdRange.start.toISOString();
                    const end = this.mode === ActivityMode.LIST ? this.getEndDate().toISOString() : this.filter.ytdRange.end.toISOString();
                    const params = { building: this.buildingId, start, end, type: [] };
                    const response = await this.ActivityService.getActivities(params);
                    this.activities = response.payload;

                    this.annualizedSavings = await this.ActivityService.getAnnualizedSavings(start, end, this.building);

                    const caseParams: aq.models.prescription.CasesRequestParams = {
                        first_published_gte: start,
                        first_published_lte: end,
                        order: '-priority',
                        page_size: 1000
                    };

                    this.activitiesByType = this.groupActivities(response.payload, this.filter);
                    this.isLoadingNewBuildingData = false;
                    this.Segment.trackEvent(ActivityActions.CURRENT_ACTIVITY_LIST, this.activitiesByType);
                    this.$scope.$apply();
                } catch (err) {
                    // this is fine
                }
            });
        }

        private generateAutoDetectReport() {
            this.exportingActivities = true;
            this.resolver(async () => {
                const start = moment(this.filter.range.start).format('YYYY-MM-DD');
                const end = moment(this.filter.range.end).format('YYYY-MM-DD');
                try {
                    const report = await this.RestangularV3
                        .one('automatic-diagnostics')
                        .one('report', this.accountId)
                        .get({ start, end });
                    let csvContent = 'data:text/csv;charset=utf-8,';
                    let fileName = '';
                    csvContent += `${report}`;
                    fileName = `Automatic Diagnostic Report - ${moment().format('YYYY-MM-DD')}.csv`;
                    const link = document.createElement('a');
                    link.setAttribute('href', encodeURI(csvContent));
                    link.setAttribute('download', fileName);
                    link.click();
                } catch (e) {
                    this.Messages.error('Something went wrong generating your report!');
                }
                this.exportingActivities = false;
            });
        }

        private mapCaseToActivity(rx: aq.models.prescription.Case): SimpleActivityResponse {
            const result: SimpleActivityResponse = {
                id: null,
                title: this.PrescriptionService.getTitle(rx),
                type: aq.models.activity.ActivityType.ISSUE,
                context: {
                    type: aq.models.activity.ActivityContextType.RX,
                    contextId: rx.id,
                    contextObject: rx
                },
                createdAt: rx.first_published,
                updatedAt: rx.updated,
                messages: (!rx.case_comments ? 0 : rx.case_comments.length)
            };
            return result;
        }

        private updateFilter(filter: ActivityFilter): void {
            filter.range.start = moment(filter.range.start);
            filter.range.end = moment(filter.range.end);
            if (filter.range.start.isBefore(this.defaults.start)) {
                this.queryActivities(filter);
            }
            this.filter = angular.copy(filter);
            this.Segment.trackEvent(ActivityActions.UPDATE_ACTIVITY_FILTER, { ...filter, user: this.UserService.currentUser.id });
            this.activitiesByType = this.groupActivities(this.activities, this.filter);
        }

        private refreshActivities() {
            this.queryActivities(this.filter);
        }

        private groupActivities(activities: SimpleActivityResponse[], filter: ActivityFilter): GroupedActivities {
            const groupedActivities = new GroupedActivities();
            if (!activities) {
                return groupedActivities;
            }


            if (this.mode === ActivityMode.LIST) {
                activities = activities.filter((a) => {
                    const created = moment(a.createdAt);
                    return created.isAfter(filter.range.start) && created.isBefore(filter.range.end);
                });
            } else if (this.mode === ActivityMode.CARD) {
                const start = this.getStartDate(filter);
                activities = activities.filter((a) => {
                    const created = moment(a.createdAt);
                    return created.isAfter(start) && created.isBefore(this.defaults.end);
                });
            }


            const typeFilter = Object.keys(filter.types)
                .filter((type) => filter.types[type] === true);
            // if we have a filter value for specific types of activities, only keep those
            if (typeFilter.length > 0) {
                activities = activities
                    .filter((act) => {
                        const { context } = act;
                        const { type: contextType } = context;
                        if (!contextType) {
                            return false;
                        }
                        return typeFilter.indexOf(contextType) !== -1;
                    });
            }

            groupedActivities
                .groupByClassification(filter, activities, this.annualizedSavings, this.mode, this.building)
                .removeEmpty();

            if (this.mode === ActivityMode.CARD) {
                groupedActivities
                    .remove(ProductCategory.ALERT)
                    .remove(ProductCategory.NOTE)
                    .remove(ProductCategory.OTHER);
            }

            this.hasActivityResults = !groupedActivities.isEmpty();
            return groupedActivities;
        }
    }


    export const ActivityFeed: ng.IComponentOptions = {
        controller: _ActivityFeed,
        controllerAs: 'vm',
        templateUrl: 'app/common/components/activities/feed/activity-feed.html',
        bindings: {
            title: '<?',
            accountId: '<',
            buildingId: '<',
            mode: '<?',
            refreshActivities: '&'
        }
    };

    angular.module('aq.ui').component('activityFeed', ActivityFeed);
}

/**
 * Model implementations
 */
declare let textural;
namespace aq.common.models {
    /**
     * Account model (partially implemented)
     */
    export interface Account extends restangular.IElement {
        id: string;
        name: string;
        accountName: string;
        timeZoneId: string;
        measurementSystem: string;
        currencyUnit: string;
        active: boolean;
        language?: string;
        buildings?: Building[];
        buildingGroups?: any[];
        imageUrl?: string;
    }

    /**
     * Building model (partially implemented)
     */
    export interface Building extends restangular.IElement {
        id?: string;
        buildingId: string;
        index?: number;
        archived?: boolean;
        name?: string;
        latitude?: number;
        longitude?: number;
        size?: number;
        sizeInSqFt?: number;
        occupantsCount?: number;
        timeZoneId?: string;
        fiscalStartMonth?: number;
        imageUrl?: string;
        account?: number;
        buildingGroup?: number;
        energyStarScore: EnergyStarScoreResponse;
        address?: string;
        city?: string;
        state?: string;
        zipCode?: string;
        sharedAccounts: any[];
        status?: string;
        country?: string;
        personnel?: Employee[];
        isUpdate?: boolean;
        isUpdatePersonnel?: boolean;
        dataCommissionDate?: string;
        mainSourceOfHeating?: string;
        mainEnergySourceOfHeating?: string;
        mainSourceOfCooling?: string;
        mainEnergySourceOfCooling?: string;
        numberOfChillers?: number;
        enticFacilityId?: string;
        enticImpactDate?: string;
        purpose?: number;
    }

    /**
     * Building model (partially implemented)
     */
    export interface TransientBuilding extends restangular.IElement {
        id?: string;
        buildingId: string;
        name?: string;
        size?: number;
        account?: number;
        address?: string;
        city?: string;
        state?: string;
        zipCode?: string;
        country?: string;
        personnel?: Employee[];
        salesforceId?: string;
        index?: number;
    }

    export interface BuildingPersonnel {
        id?: number;
        index?: number;
        name?: string;
        email?: string;
        phone?: string;
        user?: number;
        building?: number;
        persona?: 'ASSET_MANAGER' | 'PROPERTY_MANAGER' | 'BUILDING_ENGINEER';
        sortOrder?: number;
    }

    export interface Employee {
        id?: number;
        user?: aq.user.User;
    }

    /**
     * BuildingTag model
     */
    export interface BuildingTag extends restangular.IElement {
        id?: string;
        type?: string;
        building?: number;
        name: string;
    }

    /**
     * BuildingTag model
     */
    export interface BuildingTag extends restangular.IElement {
        id?: string;
        type?: string;
        building?: number;
        name: string;
    }

    export interface EnergyStarScoreResponse {
        id?: number;
        building?: any;
        lastUpdated: string;
        score: number;
    }

    export interface Point extends restangular.IElement {
        id?: string;
        name?: string;
        type?: string;
        collector: number;
        collectorName: string;
    }

    export interface BmsPoint extends restangular.IElement {
        bmsPointName: string;
        deviceName: string;
        deviceId: number;
    }

    export interface UtilityService extends restangular.IElement {
        type: string;
        defaultBlendedRate: number;
        urjanetEnrollment?: number;
    }

    export interface EnergyNote extends restangular.IElement {
        id: string;
        url: string;
        title: string;
        body: string;
        dataContext: DataContext;
        media: any;
        createdOn: string;

    }

    export interface DataContext extends restangular.IElement {
        startDate: string;
        endDate: string;
        measure: string;
        queryable: string;
        unit: string;
        peakValue: number;
        totalUsage: number;
        selectedPeriodPeak: number;
        selectedPeriodTotal: number;
        percentOfTotal: number;
        interval: string;
        docraptorUrl: string;
        selection: GraphParameters;
    }

    export interface GraphParameters extends restangular.IElement {
        xFrom: number;
        xTo: number;
        yFrom: number;
        yTo: number;
        startDate: string;
        endDate: string;
    }

    export interface Measure extends restangular.IElement {
        id: string;
        name: string;
        userDefined: boolean;
        utility: boolean;
        usageUnit: string;
        demandUnit: string;
        usageApiUnit: string;
        demandApiUnit: string;
    }

    export interface Unit {
        apiUnit?: string;
        conversionMultiplier?: number;
        label?: string;
        serviceType?: string;
        subunit?: string;
        unit?: string;
        value?: string;
        field?: string;
    }

    export type AppAccess = 'NONE' | 'READ_ONLY' | 'EDIT' | 'FULL_ACCESS';

    export interface App {
        accessList: AppAccess[];
        accessScheme: 'VEF';
        apiRestriction: boolean;
        description: string;
        icon: string;
        id: number;
        name: string;
        priority: number;
        state: string;
        stateData: Record<string, any>;
    }

    export interface Profile {
        account: Account;
        accountName: string;
        apps: App[];
        hasFullVisibility: boolean;
        id: number;
        invitationIssued: string;
        invitationStatus: 'ACCEPTED';
        reports: number[];
        roles: number[];
        visibilities: number[];
        portfolioUser?: boolean;
    }

    export interface User {
        id?: number;
        country?: string;
        currentDrillMode?: string;
        currentProfile?: any;
        defaultDrillMode?: string;
        defaultMetric?: string;
        email?: string;
        firstAccountName?: string;
        firstName?: string;
        fullName?: string;
        language?: string;
        lastLoginDate?: string;
        lastLoginIP?: string;
        lastName?: string;
        persona?: string;
        measurementSystem?: string;
        phoneNumber?: string;
        profiles?: any[];
        scheduledReports?: any[];
        timeFormat?: string;
        timeZoneId?: string;
        timeZoneOffsetInMillis?: number;
        timeZoneOffsetInMinutes?: number;
        userCreated?: string;
        userName?: string;
        userType?: string;

        department?: any;
        role?: any;
        image?: string;
    }

    export interface NotificationSender {
        id: string | number;
        name: string;
        notificationType;
        shouldSendToAdmin: boolean;
    }

    export interface Collector extends restangular.IElement {
        deviceIdentifier?: string;
        primaryBuilding?: string | Building;
        name: string;
        buildings?: string[] | Building[];
        collectorClass?: string | aq.admin.meters.deviceClasses.CollectorClass;
        childCollectors?: string[] | Collector[];
        parentCollector?: string | Collector;
        points?: string[] | Point[];
        replications?: any[];
        manualReadings?: any[];
        archived?: boolean;
        creationTime?: Date;
        isUtilityMeter?: boolean;
        utilityService?: string | UtilityService;
        usenetmetering?: boolean;
        multiplier?: number;
        readingsMultiplier?: number;
        timeout: number;
        physicalSerialNumber?: string;
        virtualMeterEquation?: any;
        metrics?: aq.common.Enums.ServiceType[];
    }

    export type DeviceCategory =
        'METER' |
        'NETWORKING' |
        'SENSOR' |
        'EQUIPMENT' |
        'OTHER';


    export type Protocol =
        'GATEWAY' |
        'MODBUS' |
        'AQUAMESH' |
        'PULSE';


    export type DeviceMeasure =
        'ELECTRICITY' |
        'WATER' |
        'GAS' |
        'HEAT' |
        'STEAM';

    export enum DeviceStatusLabel {
        NEVER_REPORTED = 'NEVER_REPORTED',
        ONLINE = 'ONLINE',
        OFFLINE = 'OFFLINE',
        WAITING = 'WAITING'
    }

    export interface DeviceLinkGETResponse {
        deviceId: string;
        name: string;
        inputOptions: string[];
    }

    export interface DeviceLinkPOSTRequest {
        id: string;
        device: string;
        inputId: string;
        protocol: aq.common.models.Protocol;
    }

    export interface DeviceLinkDELETERequest {
        id: string;
        device: string;
    }

    export interface NetworkTopology {
        deviceId: string;
        deviceName: string;
        deviceLocation?: string;
        deviceCategory: DeviceCategory;
        deviceClass: DeviceClass;
        protocol: Protocol;
        inputId?: string;
        measure?: DeviceMeasure;
        deviceFields: any;
        isQueriedElement: boolean; // is this the device i queried for topology
        parentNetworkConnectionStatus?: 'GOOD' | 'AVERAGE' | 'POOR';
        parentConnectionRssi?: number;
        children: NetworkTopology[];
    }

    // always starts at the root of the tree
    export interface DeviceTopology {
        deviceId: string;
        deviceName: string;
        deviceLocation?: string;
        deviceCategory: DeviceCategory;
        deviceClass: DeviceClass;
        protocol: Protocol;
        inputId?: string;
        measure?: DeviceMeasure;
        deviceFields: any;
        isQueriedElement: boolean; // is this the device i queried for topology
        children: DeviceTopology[];
    }

    export interface DeviceExtraFields {
        [key: string]: {
            label?: string;
            name?: string;
            icon?: string;
            type?: string;
            hint?: string;
            pattern?: string;
            value?: string;
            unique?: boolean;
            hide?: boolean;
            required?: boolean;
            admin?: boolean;
        };
    }

    // list of device properties to keep for api calls. all new fields in the interface need to also go here.
    export const DeviceProperties = [
        'id',
        'measure',
        'serialNumber',
        'building',
        'name',
        'enabled',
        'image',
        'images',
        'description',
        'floor',
        'space',
        'location',
        'locationTag',
        'created',
        'fields',
        'tenantLinks',
        'gateway',
        'utilityMeter',
        'intervalMeter',
        'manualMeter',
        'icon',
        'collectorMetric',
        'links',
        'modified',
        'deviceCategory',
        'deviceClass',
        'collectorId'
    ];

    export interface Device {
        id?: string;
        measure?: DeviceMeasure;
        serialNumber?: string;
        building?: string;
        name?: string;
        enabled?: boolean;
        image?: string;
        images?: DeviceImage[];
        imageThumbnailUrl?: string;
        imageUrl?: string;
        description?: string;
        floor?: number;
        space?: number;
        location?: string;
        locationTag?: BuildingTag;
        created?: string;
        fields?: DeviceExtraFields;
        tenantLinks?: DeviceTenantLink[];
        linkedBuildings?: { id: string; name: string }[];
        deviceCategory?: DeviceCategory;
        deviceClass?: DeviceClass;
        collectorId?: number;
        gateway?: boolean;
        isUtilityMeter?: boolean;
        isIntervalMeter?: boolean;
        // TODO: replace icon and metric
        icon?: string;
        collectorMetric?: string;
        links?: DeviceLink[];
        viewOnly?: {
            selectedDeviceClass: DeviceClass;
            selectedDeviceClassName: string;
            tenants: Tenant[];
        };
        commissioned?: boolean;
        autoCommissioning?: boolean;
        commissioningDate?: string;
        firstReportedTime?: string;
    }

    export interface DeviceImage {
        id?: number;
        url: string;
    }

    export interface DeviceLink {
        device: Device;
        protocol: Protocol;
        pulseId?: string;
    }

    export interface DeviceElement extends Device, restangular.IElement {
    }

    export interface DataStatus {
        id: number;
        status: 'ONLINE' | 'OFFLINE' | 'NEVER_REPORTED';
        lastReportedTime: string;
    }

    export interface NetworkStatus {
        startDeviceId: string;
        endDeviceId: string;
        rssi: number;
        status: 'GOOD' | 'AVERAGE' | 'POOR';
    }

    export interface Floor {
        building: number;
        id: number;
        name: string;
        spaces: Space[];
        orderIndex: number;
    }

    export interface Space {
        building: number;
        id: number;
        name: string;
    }

    export interface Source {
        id: number;
        name: string;
    }

    export interface Tenant {
        id: any;
        name: string;
        size: number;
    }

    export interface DeviceTenantLink {
        id: any;
        active: boolean;
        tenant: {
            id: any;
            name: string;
        };
    }

    export interface DeviceTenantLinkFull extends restangular.IElement {
        id: any;
        active: boolean;
        device: {
            id: any;
            name: string;
            status: string;
        };
        tenant: number;
    }

    export interface DeviceClass {
        make: string;
        series: string;
        model: string;
        fields?: DeviceExtraFields;
        measure?: any;
        upwardCommunications?: string[];
        downwardCommunications?: string[];
    }

    export interface DeviceClassificationCreationRequest {
        category: DeviceCategory;
        make: string;
        series: string;
        model: string;
        measure: number;
        utilityMeter: boolean;
    }

    /**
     * DeviceClassifications is a hashmap of:
     * @enum DeviceCategory -> make -> series: model[string]
     *
     * {
     *   'METER':{
     *       'Obvius': {
     *           'Energy Deck Meter': ['e50', 'e60d']
     *       },
     *       'Aquicore': {
     *           'Power Meter': ['v1', '3i']
     *       }
     *   }
     * }
     *
     * @export
     * @interface DeviceClassifications
     */
    export interface DeviceClassifications {
        [key: string]: {
            [key: string]: {
                [key: string]: string[];
            };
        };
    }

    export interface DeviceClassNameByCategory {
        [key: string]: DeviceClassName[];
    }

    export interface DeviceClassName {
        name: string;
        category: DeviceCategory;
        make: string;
        series: string;
        model: string;
        measure: DeviceMeasure;
    }

    export interface DeviceCreationRequest {
        name: string;
        building: string;
        deviceCategory: DeviceCategory;
        deviceClass?: {
            make: string;
            series: string;
            model: string;
        };
        tenantLinks?: DeviceTenantLink[];
        serialNumber?: string;
        image?: string;
        images?: DeviceImage[];
        description?: string;
        location?: string;
        locationTag?: BuildingTag;
        utilityMeter?: boolean;
        manualMeter?: boolean;
        link?: {
            id: string; // the device I am creating/linking from
            device: string; // the device I am linking to
            inputId: string; // the input (modbus, pulse) id I am connecting on
            protocol: Protocol;
        };
        viewOnly: {
            tenants: Tenant[];
        };
        fields?: DeviceExtraFields;
    }

    export interface ManualReading {
        id?: string;
        timestamp: any;
        value: number;
        imageUrl: string;
        imageThumbnailUrl?: string;
        collector: any;
        viewOnly: {
            date: any;
            hour: number;
            minute: number;
        };
    }

    export interface ManualReadingElement extends restangular.IElement, ManualReading {

    }

    export interface WorkflowStatus {
        numStepsCompleted: number;
        totalSteps: number;
        steps: WorkflowStep[];
        commissioned: boolean;
        commissioningDate: string;
    }

    export interface WorkflowStep {
        name: string;
        description: string;
        complete?: boolean; // view only
        status: 'COMPLETE' | 'ERROR' | 'INCOMPLETE';
        order: number;
        actions: WorkflowStepAction[];
    }

    export interface WorkflowStepAction {
        type: 'HREF' | 'HANDLER';
        value: string;
    }

    export interface EntityMenuAction {
        label: string;
        cb: Function;
        icon?: string;
    }

    export interface SimpleEnum {
        [name: string]: {
            label: string;
        };
    }

    export interface TimeZone {
        value: string;
        label: string;
        offsetInMillis: number;
        default: boolean;
    }

    export interface DataChartingOptionsResponse {
        queryables: { [name: string]: DataChartingOptionsQueryableResponse };
    }
    export interface DataChartingOptionsQueryableResponse {
        name: string;
        queryableUrl: string;
        units: DataChartingOptionsUnitResponse[];
    }

    export interface DataChartingOptionsUnitResponse {
        measure: string;
        metric: string;
        unit: string;
        scale: number;
        abbreviation: string;
        color: string;
    }

    export interface DataResponse {
        data: TimeSeriesDatum[];
    }

    export interface TimeSeriesDatum {
        timestamp: number;
        value: number;
    }

    export interface StateMetaData {
        breadcrumb?: string;
        icon?: string;
        title?: string;
        aria?: string;
    }

    export interface SidebarNavItem {
        title: string;
        state?: string;
        stateData?: any;
        stateOptions?: any;
        icon?: string;
        aria?: string;
        isActiveFn?: () => boolean;
    }

    export interface SubMenuItems {
        title: string;
        icon?: string;
        stateData?: any;
        stateOptions?: any;
        aria?: any;
    }

    export interface NestedSidebarNavItem {
        title: string;
        state?: string;
        stateData?: any;
        stateOptions?: any;
        icon?: string;
        aria?: string;
        subMenuItems: SubMenuItems[];
        isActiveFn?: () => boolean;
    }

    export interface UserCreateRequest {
        userName: string;
        firstName: string;
        lastName: string;
        password: string;
        email: string;
        image: string;
        title: string;
        phoneNumber: string;
        imageUrl: string;
        imageThumbnailUrl: string;
    }

    export interface UtilityServiceModel extends restangular.IElement {
        name: string;
        type: string;
        utilityCompany: string;
        account: string;
        rateCalculation: string;
        tariffSchedule: string;
        defaultBlendedRate: number;
        collectors: any[];
        accountDefault: boolean;
        collectorSearchText: string;
        selectedCollector: any;
        building: number;
        status: string;
    }

    export interface BuildingScheduleEvent extends restangular.IElement {
        name: string;
        category: number;
        categoryName: string;
        color: string;
        week: string[];
        timeStart: string;
        timeEnd: string;
        dateStart: string;
        dateEnd: string;
    }

    export interface Integration extends restangular.IElement {
        account: number;
        integrationApp: number;
        appName: string;
    }

    export interface SpikeData {
        start: string;
        end: string;
        collectorId: number;
        utility: string;
    }

    export interface DeviceDataStatus {
        id: string;
        status: string;
        lastReportedTime: string;
    }

    export interface AppAccessObject {
        FULL_ACCESS: boolean;
        EDIT: boolean;
        READ_ONLY: boolean;
        NONE: boolean;
    }

    export interface AuthAppAccess {
        [appName: string]: common.models.AppAccessObject;
    }

    export interface MeterDataUnit extends Unit {
        apiUnit: string;
        series: {
            name: string;
            data?: any[];
            color: string;
        };
        yAxis: any;
    }

    export interface NamedItem {
        id: number | string;
        name: string;
    }
}

'use strict';

const PROJECT_ESTIMATION = 'Project Estimation';

angular
    .module('aq.reports',
        [
            'aq-main',
            'restangular',
            'ui.router',
            'aq.ui.docraptor',
            'aq.services.options',
            'aq.filters.formatters',
            'aq.filters.units',
            'aq.filters.tools',
            'aq.filters.math',
            'aq.filters.time',
            'aq.services.data',
            'aq.ui.highChart',
            'energyInsights',
            'aq.ui.color'
        ])
    .config((RestangularProvider: restangular.IProvider, $stateProvider: ng.ui.IStateProvider) => {
        $stateProvider
            .state('aq.reports', {
                url: '/reports',
                abstract: true,
                template: '<ui-view></ui-view>',
                data: {
                    hideNavbar: true,
                    appName: 'reports'
                },
                resolve: {
                    accountId($location): any {
                        return $location.search().accountId;
                    },
                    account(waitForAuthToken, RestangularV3, accountId): any {
                        if (accountId) {
                            return RestangularV3.one('accounts', accountId).get();
                        }
                        else {
                            return null;
                        }
                    },
                    buildingId($location): any {
                        return $location.search().buildingId;
                    },
                    building(waitForAuthToken, Restangular, buildingId, accountId): any {
                        if (buildingId && accountId) {
                            return Restangular
                                .one('accounts', accountId)
                                .one('buildings', buildingId)
                                .get({
                                    single: true,
                                    inflate: 'buildingPurpose,collectors,buildingOccupancies,targets,targets.measure,targets.targetItems'
                                });
                        } else {
                            return null;
                        }
                    },
                    date($location: ng.ILocationService): number {
                        const dateParam = $location.search().date;
                        if (dateParam) {
                            return parseInt(dateParam);
                        }
                        return moment().utc().startOf('day').valueOf();
                    }
                }
            }).state('aq.reports.BER', {
                url: '/summary/monthlyBuildingEngineer',
                controller: 'MonthlyBuildingEngineerMainCtrl',
                controllerAs: 'vm',
                templateUrl: 'app/reports/summary/monthlyBuildingEngineer/controllers/main.html',
                data: {
                    hideNavbar: true
                },
                resolve: {
                    availableMetrics(building, MonthlyBuildingEngineerData): any {
                        return MonthlyBuildingEngineerData.availableMetrics(building);
                    },
                    electricityData(building, MonthlyBuildingEngineerData, availableMetrics): any {
                        return MonthlyBuildingEngineerData.getElectricityData(building, availableMetrics);
                    },
                    waterData(building, MonthlyBuildingEngineerData, availableMetrics): any {
                        return MonthlyBuildingEngineerData.getWaterData(building, availableMetrics);
                    },
                    gasData(building, MonthlyBuildingEngineerData, availableMetrics): any {
                        return MonthlyBuildingEngineerData.getGasData(building, availableMetrics);
                    },
                    steamData(building, MonthlyBuildingEngineerData, availableMetrics): any {
                        return MonthlyBuildingEngineerData.getSteamData(building, availableMetrics);
                    },
                    degreeDaysData(building, MonthlyBuildingEngineerData): any {
                        return MonthlyBuildingEngineerData.getDegreeDayData(building);
                    },
                    reportTime($location: ng.ILocationService, building: aq.common.models.Building): moment.Moment {
                        if ($location.search().date) {
                            const epoch = parseInt($location.search().date);
                            const localDate = moment(epoch).utc().format('YYYY-MM-DDTHH:mm');
                            return moment.tz(localDate, building.timeZoneId);
                        }
                        return moment();
                    }
                }
            })
            .state('aq.reports.executive', {
                url: '/summary/executive',
                controller: 'executiveReportCtrl',
                controllerAs: 'vm',
                templateUrl: 'app/reports/summary/executive/executive.html',
                data: {
                    hideNavbar: true
                },
                resolve: {
                    metric(Restangular, account: aq.common.models.Account, waitForAuthToken): ng.IPromise<aq.common.models.Account> {
                        return Restangular.one('accounts', account.id).customGET('queryRealUnits');
                    },
                    buildings(Restangular, account: aq.common.models.Account, waitForAuthToken): ng.IPromise<aq.common.models.Building[]> {
                        return Restangular.one('accounts', account.id)
                            .all('buildings')
                            .getList({ inflate: 'buildingGroup' }).then((buildings) => {
                                return buildings.filter((building) => {
                                    return building.status === 'ACTIVE';
                                });
                            });
                    }
                }
            })
            .state('aq.reports.alert', {
                url: '/summary/portfolio-alert',
                controller: 'AlertReportCtrl',
                controllerAs: 'vm',
                templateUrl: 'app/reports/summary/alert/alert.html',
                resolve: {
                    buildings(waitForAuthToken, RestangularV3: restangular.IService): ng.IPromise<any> {
                        return RestangularV3.all('buildings').getList()
                            .then((buildings) => _.filter(buildings, (b) => b.status === 'ACTIVE'));
                    },
                    buildingGroups(
                        RestangularV3: restangular.IService,
                        account: aq.common.models.Account,
                        waitForAuthToken
                    ): restangular.ICollectionPromise<any> {
                        return RestangularV3.all('building-groups').getList();
                    },
                    buildingPersonnels(buildings: aq.common.models.Building[], $q: ng.IQService) {
                        const promises = _.map(buildings, (building: aq.common.models.Building) => {
                            return building.all('personnel').getList()
                                .then((result) => {
                                    return {
                                        buildingId: building.id,
                                        personnel: result
                                    };
                                }, (error) => {
                                    return {
                                        buildingId: building.id,
                                        personnel: []
                                    };
                                });
                        });
                        return $q.all(promises);
                    },
                    startDate($location: ng.ILocationService): number {
                        if ($location.search().startDate) {
                            return parseInt($location.search().startDate);
                        } else {
                            return null;
                        }
                    },
                    schedulingInterval($location: ng.ILocationService): any {
                        if ($location.search().schedulingInterval) {
                            return $location.search().schedulingInterval;
                        } else {
                            return null;
                        }
                    },
                    alerts(
                        RestangularV3: restangular.IService,
                        date: number,
                        startDate: number,
                        account: aq.common.models.Account,
                        schedulingInterval: string,
                        waitForAuthToken
                    ): restangular.IPromise<any> {
                        const interval = schedulingInterval ? schedulingInterval : 'week';
                        const { timeZoneId, id: accountId } = account;
                        const localEndDate = moment(date).utc().format('YYYY-MM-DD');
                        const end = moment.tz(localEndDate, timeZoneId).format();
                        const localStartDate = moment(startDate).utc().format('YYYY-MM-DD');
                        const start = startDate
                            ? moment.tz(localStartDate, timeZoneId).format()
                            : moment.tz(localEndDate, timeZoneId).subtract(1, interval).format();
                        return RestangularV3.one('alert-data')
                            .one('stats')
                            .one('for-account')
                            .get({ start, end, accountId });
                    }
                }
            })
            .state('aq.reports.building', {
                abstract: true,
                template: '<ui-view></ui-view>',
                resolve: {
                    lang(waitForAuthToken, UserService): any {
                        return UserService.initLanguage();
                    },
                    currentDate(lang, $location, building: aq.common.models.Building): moment.Moment {
                        if ($location.search().date) {
                            const localDate = moment
                                .unix(parseInt($location.search().date) / 1000)
                                .utc()
                                .format('YYYY-MM-DDTHH:mm');
                            return moment.tz(localDate, building.timeZoneId);
                        }
                        return moment();
                    },
                    startDate(lang, $location, building: aq.common.models.Building): moment.Moment {
                        if ($location.search().startDate) {
                            const localDate = moment
                                .unix(parseInt($location.search().startDate) / 1000)
                                .utc()
                                .format('YYYY-MM-DDTHH:mm');
                            return moment.tz(localDate, building.timeZoneId);
                        }
                        let tempMoment = moment();
                        tempMoment.subtract(1, 'month');
                        return tempMoment;
                    },
                    endDate(lang, $location, building: aq.common.models.Building): moment.Moment {
                        if ($location.search().endDate) {
                            const localDate = moment
                                .unix(parseInt($location.search().endDate) / 1000)
                                .utc()
                                .format('YYYY-MM-DDTHH:mm');
                            return moment.tz(localDate, building.timeZoneId);
                        }
                        return moment();
                    },
                    building(waitForAuthToken, accountId, buildingId, Restangular): any {
                        return Restangular.one('accounts', accountId).one('buildings', buildingId)
                            .get({ single: true, inflate: 'buildingPurpose,buildingOccupancies,collectors' });
                    },
                    energyStarScore(waitForAuthToken, accountId, buildingId, EnergyStarApiService): any {
                        return EnergyStarApiService.getEnergyStarScoreForBuilding(accountId, buildingId);
                    },
                    functionalities(waitForAuthToken, accountId, Restangular): any {
                        return Restangular.one('accounts', accountId).getList('Functionalities');
                    },
                    options(OptionsService, accountId, account): any {
                        return OptionsService.init(accountId, account.measurementSystem, account.currencyUnit);
                    },
                    availableMetrics(building, MonthlyBuildingEngineerData): any {
                        return MonthlyBuildingEngineerData.availableMetrics(building);
                    },
                    meters(waitForAuthToken, buildingId, RestangularV3): any {
                        return RestangularV3.all('devices').getList({ buildingId, category: 'METER' });
                    }
                }
            })
            .state('aq.reports.building.daily', {
                url: '/summary/daily',
                templateUrl: 'app/reports/summary/building/dailyBuildingReport.html',
                controller: 'DailyBuildingCtrl',
                controllerAs: 'vm'
            })
            .state('aq.reports.building.monthly', {
                url: '/summary/monthly',
                templateUrl: 'app/reports/summary/building/monthlyBuildingReport.html',
                controller: 'MonthlyBuildingCtrl',
                controllerAs: 'vm'
            })
            .state('aq.reports.building.customDateRange', {
                url: '/summary/customDateRange',
                templateUrl: 'app/reports/summary/customDateRange/controllers/customDateRangeBuildingReport.html',
                controller: 'CustomDateRangeBuildingReportCtrl',
                controllerAs: 'vm',
                resolve: {
                    buildings(waitForAuthToken, RestangularV3: restangular.IService): ng.IPromise<any> {
                        return RestangularV3.all('buildings').getList()
                            .then((buildings) => _.filter(buildings, (b) => b.status === 'ACTIVE'));
                    },
                    buildingGroups(
                        RestangularV3: restangular.IService,
                        account: aq.common.models.Account,
                        waitForAuthToken
                    ): restangular.ICollectionPromise<any> {
                        return RestangularV3.all('building-groups').getList();
                    },
                    buildingPersonnels(buildings: aq.common.models.Building[], $q: ng.IQService) {
                        const promises = _.map(buildings, (building: aq.common.models.Building) => {
                            return building.all('personnel').getList()
                                .then((result) => {
                                    return {
                                        buildingId: building.id,
                                        personnel: result
                                    };
                                }, (error) => {
                                    return {
                                        buildingId: building.id,
                                        personnel: []
                                    };
                                });
                        });
                        return $q.all(promises);
                    }
                }
            })
            .state('aq.reports.dailyDemo', {
                url: '/summary/dailyDemo',
                controller: 'dailyReportDemoCtrl',
                templateUrl: 'app/reports/summary/dailyDemo/controllers/main.html',
                data: {
                    hideNavbar: true
                }
            })
            .state('aq.reports.vornadoTenantReport', {
                url: '/billing/vornadoTenantReport?accountId&buildingId&billingId&invoiceId&tenantId',
                controller: 'VornadoTenantReportCtrl',
                controllerAs: 'vm',
                templateUrl: 'app/reports/billing/vornadoTenantReport/controllers/vornadoTenantReportCtrl.html',
                data: {
                    hideNavbar: true
                },
                resolve: {
                    invoice(waitForAuthToken, $stateParams: ng.ui.IStateParamsService, RestangularV3: restangular.IService) {
                        return RestangularV3.one('tenant-invoices', $stateParams.invoiceId)
                            .get();
                    },
                    billing(waitForAuthToken, $stateParams: ng.ui.IStateParamsService, RestangularV3: restangular.IService): restangular.IPromise<any> {
                        return RestangularV3.one('billings', $stateParams.billingId)
                            .get();
                    },
                    tenant(waitForAuthToken, $stateParams: ng.ui.IStateParamsService, RestangularV3: restangular.IService): restangular.IPromise<any> {
                        return RestangularV3.one('tenants', $stateParams.tenantId)
                            .get();
                    },
                    tenantChargeValues(waitForAuthToken, $stateParams: ng.ui.IStateParamsService, RestangularV3: restangular.IService): restangular.ICollectionPromise<any> {
                        return RestangularV3.all('tenant-charge-values')
                            .all('for-tenant-invoice')
                            .getList({ tenantInvoiceId: $stateParams.invoiceId });
                    },
                    historicalUsage(waitForAuthToken, $stateParams: ng.ui.IStateParamsService, RestangularV3: restangular.IService): restangular.ICollectionPromise<any> {
                        return RestangularV3.all('tenant-invoices')
                            .all('for-historical-usage')
                            .getList({ tenantInvoiceId: $stateParams.invoiceId });
                    },
                    setup(waitForAuthToken, $stateParams: ng.ui.IStateParamsService, RestangularV3: restangular.IService): restangular.IPromise<any> {
                        return RestangularV3.one('billing-setups')
                            .get({ buildingId: $stateParams.buildingId });
                    },
                    options(OptionsService, account): any {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    }
                }
            })
            .state('aq.reports.passThroughSummary', {
                url: '/custom/passThroughSummary?accountId&buildingId&billingId',
                controller: 'PassThroughSummaryCtrl',
                controllerAs: 'vm',
                templateUrl: 'app/reports/billing/passThroughSummary/controllers/passThroughSummaryCtrl.html',
                data: {
                    hideNavbar: true
                },
                resolve: {
                    billing(waitForAuthToken, $stateParams: ng.ui.IStateParamsService, RestangularV3: restangular.IService) {
                        return RestangularV3.one('billings', $stateParams.billingId)
                            .get();
                    },
                    tenants(waitForAuthToken, RestangularV3: restangular.IService, buildingId: string): restangular.ICollectionPromise<any> {
                        return RestangularV3.all('tenants').getList({ buildingId });
                    },
                    tenantChargeValues(
                        waitForAuthToken,
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService
                    ): ng.IPromise<any> {
                        return DataStore.getList(RestangularV3.one(''), 'tenant-charge-values', { monthlyBillingId: $stateParams.billingId });
                    },
                    setup(waitForAuthToken, $stateParams: ng.ui.IStateParamsService, RestangularV3: restangular.IService): restangular.IPromise<any> {
                        return RestangularV3.one('billing-setups')
                            .get({ buildingId: $stateParams.buildingId });
                    },
                    building(
                        waitForAuthToken,
                        DataStore: aq.common.DataStore,
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService
                    ): any {
                        return DataStore.get(RestangularV3.one('buildings', $stateParams.buildingId), {}, true);
                    },
                    periodTenantServices(
                        waitForAuthToken,
                        RestangularV3: restangular.IService,
                        DataStore: aq.common.DataStore,
                        $stateParams: ng.ui.IStateParamsService
                    ): ng.IPromise<any[]> {
                        return DataStore.getList(RestangularV3.one(''), 'period-tenant-services', { monthlyBillingId: $stateParams.billingId });
                    },
                    tenantGroups(
                        waitForAuthToken,
                        RestangularV3: restangular.IService,
                        $stateParams: ng.ui.IStateParamsService
                    ): restangular.ICollectionPromise<any> {
                        return RestangularV3.all('tenant-groups').getList({ buildingId: $stateParams.buildingId });
                    },
                    options(OptionsService, account): any {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    }
                }
            })
            .state('aq.reports.billingInvoice', {
                url: '/billing/invoice?accountId&buildingId&billingId&invoiceId&tenantId',
                controller: 'reportsInvoiceCtrl',
                controllerAs: 'vm',
                templateUrl: 'app/reports/billing/invoice/controllers/invoiceCtrl.html',
                data: {
                    hideNavbar: true
                },
                resolve: {
                    invoice(
                        waitForAuthToken,
                        $stateParams: ng.ui.IStateParamsService,
                        RestangularV3: restangular.IService
                    ): restangular.IPromise<any> {
                        return RestangularV3.one('tenant-invoices', $stateParams.invoiceId)
                            .get();
                    },
                    billing(
                        waitForAuthToken,
                        $stateParams: ng.ui.IStateParamsService,
                        RestangularV3: restangular.IService
                    ): restangular.IPromise<any> {
                        return RestangularV3.one('billings', $stateParams.billingId)
                            .get();
                    },
                    tenant(
                        waitForAuthToken,
                        $stateParams: ng.ui.IStateParamsService,
                        RestangularV3: restangular.IService
                    ): restangular.IPromise<any> {
                        return RestangularV3.one('tenants', $stateParams.tenantId)
                            .get();
                    },
                    tenantChargeValues(
                        waitForAuthToken,
                        $stateParams: ng.ui.IStateParamsService,
                        RestangularV3: restangular.IService
                    ): restangular.ICollectionPromise<any> {
                        return RestangularV3.all('tenant-charge-values')
                            .all('for-tenant-invoice')
                            .getList({ tenantInvoiceId: $stateParams.invoiceId });
                    },
                    historicalUsage(
                        waitForAuthToken,
                        $stateParams: ng.ui.IStateParamsService,
                        RestangularV3: restangular.IService
                    ): restangular.ICollectionPromise<any> {
                        return RestangularV3.all('tenant-invoices')
                            .all('for-historical-usage')
                            .getList({ tenantInvoiceId: $stateParams.invoiceId });
                    },
                    setup(
                        waitForAuthToken,
                        $stateParams: ng.ui.IStateParamsService,
                        RestangularV3: restangular.IService
                    ): restangular.IPromise<any> {
                        return RestangularV3.one('billing-setups')
                            .get({ buildingId: $stateParams.buildingId });
                    },
                    options(OptionsService, account): any {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    },
                    residents(
                        waitForAuthToken,
                        invoice,
                        RestangularV3: restangular.IService
                    ): restangular.IPromise<any> | null {
                        if (invoice.lease) {
                            return RestangularV3.all('residents')
                                .customGET('', { leaseId: invoice.lease.id });
                        }
                        return null;
                    }
                }
            })
            .state('aq.reports.projectDetails', {
                url: '/project/projectDetails',
                controller: 'ProjectDetailController',
                controllerAs: 'vm',
                templateUrl: 'app/reports/projectCenter/controllers/projectDetail.html',
                data: {
                    hideNavbar: true
                },
                resolve: {
                    users(
                        Restangular,
                        DataStore: aq.common.DataStore,
                        account,
                        waitForAuthToken
                    ): ng.IPromise<any> {
                        return DataStore.getList(Restangular.one('accounts', account.id), 'users', {});
                    },
                    projectId($location): any {
                        return $location.search().projectId;
                    },
                    project(waitForAuthToken, RestangularV3, projectId): any {
                        // returns a project object, you can use this to get the details of the project by passing it into controller
                        return RestangularV3.one('projects', projectId).get();
                    },
                    measures(waitForAuthToken, RestangularV3: restangular.IService, DataStore: aq.common.DataStore): ng.IPromise<any[]> {
                        return DataStore.getList(RestangularV3.one(''), 'measures');
                    },
                    projectResults(project: aq.projectCenter.Project, waitForAuthToken): restangular.IPromise<any> {
                        return project.customGET('results');
                    },
                    units(OptionsService, account): any {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit).then(function () {
                            return OptionsService.getAllUnits();
                        });
                    },
                    projectStatuses(waitForAuthToken, RestangularV3: restangular.IService): ng.IPromise<any> {
                        return RestangularV3.one('projects').one('statuses').get().then((result) => {
                            return result.originalElement;
                        });
                    },
                    projectTypes(waitForAuthToken, RestangularV3: restangular.IService): ng.IPromise<any> {
                        return RestangularV3.one('projects').one('types').get().then((result) => {
                            return result.originalElement;
                        });
                    }
                }
            })
            .state('aq.reports.portfolio', {
                url: '/summary/portfolio',
                templateUrl: 'app/reports/summary/portfolio/portfolioYTD.html',
                controller: 'PortfolioControllerYTD',
                controllerAs: 'vm',
                data: {
                    hideNavbar: true
                },
                resolve: {
                    options(OptionsService, account): any {
                        return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                    },
                    reportMonth(date): string {
                        moment.locale('en-us');
                        return moment(date).subtract(1, 'month').format('MMMM');
                    },
                    allBuildings(account): any[] {
                        // TODO: retreive all buildings for the account
                        // alternatively, modify buildingGroups endpoint to include info about 'incomplete' groups directly
                        return [];
                    },
                    buildings(waitForAuthToken, RestangularV3: restangular.IService): restangular.ICollectionPromise<any> {
                        return RestangularV3.one('').getList('buildings', { showInactive: false });
                    },
                    buildingIds(buildings): any {
                        return buildings.map(b => b.id);
                    },
                    buildingGroups(RestangularV3, allBuildings, buildings, waitForAuthToken): any {
                        return RestangularV3.all('building-groups').getList().then((groups) => {
                            _.each(groups, (group) => {
                                const filteredAllBuildings = _.filter(allBuildings, (b) => b.buildingGroup === group.id);
                                const filteredVisibleBuildings = _.filter(buildings, (b) => b.buildingGroup === group.id);
                                if (filteredAllBuildings.length > filteredVisibleBuildings.length) {
                                    group.isIncomplete = true;
                                }
                            });
                            return groups;
                        });
                    },
                    utilitySpendingBudgets(waitForAuthToken, $q: ng.IQService, RestangularV3, buildingIds, date): any {
                        const correctedDate = moment(date).dayOfYear() === 1 ? moment(date).subtract(1, 'day') : moment(date);
                        const start = moment(correctedDate).startOf('year').format('YYYY-MM-DDTHH:mm:ssZ');
                        const end = moment(correctedDate).endOf('year').format('YYYY-MM-DDTHH:mm:ssZ');

                        return RestangularV3.one('utility-spending').customGET('for-buildings',
                            {
                                start,
                                end,
                                buildingIds,
                                interval: '1mon',
                                measure: 'electricity'
                            });
                    },
                    utilitySpendingBudgetsPastYear(
                        $q: ng.IQService,
                        RestangularV3,
                        buildings,
                        buildingIds,
                        date,
                        waitForAuthToken,
                        utilitySpendingBudgets
                    ): any {
                        const correctedDate = moment(date).dayOfYear() === 1 ? moment(date).subtract(1, 'day') : moment(date);
                        const isPastDataRequired = _.some(buildings, (building) => {
                            const start = getFiscalYearStart(building, moment(correctedDate));
                            return moment(start).year() < moment(correctedDate).year();
                        });
                        if (isPastDataRequired) {
                            const pastYear = moment(correctedDate).subtract(1, 'year');
                            const pastYearStartDate = pastYear.startOf('year').format('YYYY-MM-DDTHH:mm:ssZ');
                            const pastYearEndDate = pastYear.endOf('year').format('YYYY-MM-DDTHH:mm:ssZ');
                            return RestangularV3.one('utility-spending').customGET('for-buildings',
                                {
                                    start: pastYearStartDate,
                                    end: pastYearEndDate,
                                    buildingIds,
                                    interval: '1mon',
                                    measure: 'electricity'
                                });
                        } else {
                            return [];
                        }
                    },
                    buildingTargets(Restangular, account, buildingIds, date, waitForAuthToken): any {
                        const correctedDate = moment(date).dayOfYear() === 1 ? moment(date).subtract(1, 'day') : moment(date);
                        return Restangular.one('accounts', account.id).all('targets')
                            .customGET('aggregate', {
                                inflate: 'targetItems,measure',
                                buildings: buildingIds,
                                date: correctedDate.format('YYYY-MM-DD')
                            });
                    },
                    buildingTargetsPastYear(
                        Restangular, account, buildingIds, date, waitForAuthToken, buildings
                    ): any {
                        const correctedDate = moment(date).dayOfYear() === 1 ? moment(date).subtract(1, 'day') : moment(date);
                        const isPastDataRequired = _.some(buildings, (building) => {
                            const start = getFiscalYearStart(building, moment(correctedDate));
                            return moment(start).year() < moment(correctedDate).year();
                        });
                        if (isPastDataRequired) {
                            return Restangular.one('accounts', account.id).all('targets')
                                .customGET('aggregate', {
                                    inflate: 'targetItems,measure',
                                    buildings: buildingIds,
                                    date: correctedDate.subtract(1, 'year').format('YYYY-MM-DD')
                                });
                        }
                    },
                    buildingConsumptions(
                        Restangular,
                        account,
                        buildings,
                        $q: ng.IQService,
                        options,
                        OptionsService,
                        date,
                        waitForAuthToken,
                        utilitySpendingBudgets,
                        utilitySpendingBudgetsPastYear
                    ): ng.IPromise<any> {
                        const promises = getMonthlyDataForType(Restangular, account, buildings, date, 'CONSUMPTION');
                        return $q.all(promises);
                    },
                    buildingPeakDemands(
                        Restangular,
                        account,
                        buildings,
                        $q,
                        $filter: ng.IFilterService,
                        options,
                        OptionsService,
                        date,
                        waitForAuthToken,
                        utilitySpendingBudgets,
                        utilitySpendingBudgetsPastYear
                    ): any {
                        const promises = getMonthlyDataForType(Restangular, account, buildings, date, 'PEAK_DEMAND');
                        return $q.all(promises);
                    },
                    buildingSchedules: (RestangularV3, buildings, waitForAuthToken): any => {
                        return RestangularV3.one('calendars').get()
                            .then((data) => {
                                return _.map(buildings, (building) => {
                                    return {
                                        buildingId: building.id,
                                        schedule: _.find(data, (item) => item.id == building.calendar)
                                    };
                                });
                            }, (error) => {
                                return [];
                            });
                    },
                    buildingSchedulePartitionedConsumptions(
                        Restangular,
                        account,
                        buildings,
                        $q: ng.IQService,
                        date,
                        waitForAuthToken,
                        buildingConsumptions,
                        buildingPeakDemands
                    ): any {
                        let counter = 0;
                        const batchSize = 5;
                        const defs = [$q.defer()];
                        let result = [];
                        while (buildings.length > 0 && counter < buildings.length) {
                            const nextDefer = $q.defer();
                            const lastDefer = _.last(defs);
                            defs.push(nextDefer);

                            const closure = (cnt, nextDef) => {
                                lastDefer.promise.then(() => {
                                    const batchPromises = getNextRequestBatch(Restangular, account, buildings, date, cnt, cnt + batchSize);
                                    $q.all(batchPromises).then((data) => {
                                        result = _.concat(result, data);
                                        nextDef.resolve();
                                    });
                                });
                            };
                            closure(counter, nextDefer);
                            counter += batchSize;
                        }
                        const finalDefer = _.last(defs);
                        _.first(defs).resolve([]);
                        return finalDefer.promise.then(() => {
                            return result;
                        });
                    },
                    buildingPersonnels(buildings, $q, RestangularV3, waitForAuthToken): any {
                        const promises = _.map(buildings, (building) => {
                            return RestangularV3.one('buildings', building.id).customGET('personnel')
                                .then((result) => {
                                    return {
                                        buildingId: building.id,
                                        personnel: result
                                    };
                                }, (error) => {
                                    return {
                                        buildingId: building.id,
                                        personnel: []
                                    };
                                });
                        });
                        return $q.all(promises);
                    },
                    buildingOccupancy(buildingIds, Restangular, account, waitForAuthToken): any {
                        return Restangular.one('accounts', account.id).customGET('building-occupancy', { buildings: buildingIds });
                    },
                    buildingDegreeDays(Restangular, account, buildings, $q, date, waitForAuthToken): any {
                        const promises = _.map(buildings, (building) => {
                            const start = getFiscalYearStart(building, moment(date));
                            const end = moment(date).format();
                            return Restangular.one('accounts', account.id).one('buildings', building.id)
                                .customGET('degreeDays', { start, end, interval: '1mon' })
                                .then((result) => {
                                    return {
                                        buildingId: building.id,
                                        degreeDays: result
                                    };
                                }, (error) => {
                                    return {
                                        buildingId: building.id,
                                        degreeDays: {
                                            hdd: { values: [] },
                                            cdd: { values: [] }
                                        }
                                    };
                                });
                        });
                        return $q.all(promises);
                    }
                }
            });
        const getNextRequestBatch = (Restangular, account, buildings, date, index, count): any => {
            const items = _.slice(buildings, index, count);
            const promises = _.map(items, (building) => {
                const start = moment(date).subtract(1, 'month').tz(building.timeZoneId).startOf('month').format();
                const end = moment(start).endOf('month').format();

                return Restangular.one('accounts', account.id).one('buildings', building.id)
                    .customGET('metrics', {
                        interval: '1h',
                        start,
                        end,
                        partitionId: building.calendar,
                        partitionType: 'workcalendar',
                        measure: 'ENERGY'
                    })
                    .catch(() => {
                        return {
                            id: building.id,
                            start,
                            end,
                            partitions: []
                        };
                    });
            });
            return promises;
        };
        const getBuildingTimezoneFirstDateOfMonth = (building, year, month): any => {
            const yearStartString = `${year}-${month < 10 ? 0 : ''}${month}-01T00:00:00Z`;
            const offsetStart = moment(yearStartString).tz(building.timeZoneId).utcOffset();
            const start = moment(yearStartString).tz(building.timeZoneId).add(-offsetStart, 'minutes').format();
            return start;
        };
        const getFiscalYearStart = (building, currentDate: moment.Moment): string => {
            const currentMonth = currentDate.month() + 1;
            const startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
            let startYear = currentDate.year();
            if (currentMonth <= startMonth) {
                startYear--;
            }
            return getBuildingTimezoneFirstDateOfMonth(building, startYear, startMonth);
        };
        const getFiscalYearStartMoment = (building, currentDate: moment.Moment): moment.Moment => {
            const result = moment(currentDate);
            const startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
            const monthDiff = startMonth - (currentDate.month() + 1);
            if (monthDiff > 0) {
                result.subtract(1, 'year');
            }
            result.add(monthDiff, 'month');
            return result;
        };
        const getMonthlyDataForType = (Restangular, account, buildings, date, type): ng.IPromise<any>[] => {
            const correctedDate = moment(date).dayOfYear() === 1 ? moment(date).subtract(1, 'day') : moment(date);
            let minDate: moment.Moment;
            _.each(buildings, (building) => {
                const start = getFiscalYearStartMoment(building, correctedDate);
                if (!minDate || start.isBefore(minDate)) {
                    minDate = start;
                }
            });
            const promises = [];
            const monthDate = moment(minDate);
            while (monthDate.isSameOrBefore(correctedDate)) {
                promises.push(Restangular.one('accounts', account.id).one('portfolio')
                    .customGET('insightValues', { type, date: moment(monthDate).valueOf() })
                    .catch(() => { }));
                monthDate.add(1, 'month');
            }
            return promises;
        };
    });

namespace aq.tenantbilling {
    export class InvoicingSummaryContentCtrl {
        public currentNavItem: string;
        public monthlyBilling: MonthlyTenantBilling;
        public tenantInvoices: TenantInvoice[];
        public chargeCategories: ChargeCategory[];
        public tenantChargeValues: TenantChargeValue[];
        public building: aq.common.models.Building;
        public currencyUnit: any;
        public showCompletionMessage: boolean;
        public downloadInProgress: any;
        private DOWNLOAD_TIMEOUT = 90;
        tenantLabel: string;
        /* @ngAnnotate */
        constructor(
            private OptionsService,
            public $mdDialog: ng.material.IDialogService,
            private RestangularV3: restangular.IService,
            private RestangularV3FullResponse: restangular.IService,
            private FileHandlerService: aq.services.FileHandlerService,
            private Messages: aq.services.Messages,
            private $timeout: ng.ITimeoutService,
            private Auth: aq.services.Auth
        ) {
            this.tenantLabel = Auth.hasFunctionality('Multifamily Tenant Billing') ? 'unit' : 'tenant';
        }
        public $onInit() {
            this.currentNavItem = 'summary';
            this.currencyUnit = this.OptionsService.currencyUnit();
            this.downloadInProgress = {};
            _.forEach(this.tenantInvoices, (invoice) => {
                this.downloadInProgress[invoice.id] = 0;
            });
        }
        public sumChargeCategories() {
            return _.sum(_.map(this.chargeCategories, 'totalCharge'));
        }
        public downloadInvoiceStatus(invoiceId) {
            return this.RestangularV3.one('tenant-invoices', invoiceId)
                .all('download')
                .all('invoice')
                .customGET('status', { t: Date.now() })
                .then((response) => {
                    if (response === true || response === 'true' || this.downloadInProgress[invoiceId] > this.DOWNLOAD_TIMEOUT) {
                        return this.downloadInvoice(invoiceId);
                    }
                    this.downloadInProgress[invoiceId]++;
                    this.$timeout(() => this.downloadInvoiceStatus(invoiceId), 1000);
                })
                .catch((err) => {
                    this.Messages.error('Error while generating report');
                    this.downloadInProgress[invoiceId] = 0;
                });
        }
        public downloadInvoice(invoiceId) {
            return this.RestangularV3FullResponse.one('tenant-invoices', invoiceId)
                .all('download')
                .withHttpConfig({ responseType: 'blob' })
                .customGET('invoice')
                .then((response) => {
                    this.FileHandlerService.handleFileDownload(response, 'application/pdf');
                })
                .catch(() => {
                    this.Messages.error('Error while generating report');
                })
                .finally(() => {
                    this.downloadInProgress[invoiceId] = 0;
                });
        }
        public viewChargeValue = (chargeValue: TenantChargeValue, activeTab = 'readings') => {
            this.$mdDialog.show({
                templateUrl: 'app/tenantBilling/invoicing/run/editChargeValue/editChargeValue.html',
                controller: 'EditChargeValueCtrl',
                controllerAs: 'vm',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {
                    monthlyBilling: this.monthlyBilling,
                    building: this.building,
                    chargeValue,
                    activeTab,
                    isReadOnly: true
                },
                resolve: {
                    device: () => {
                        return this.RestangularV3.all('devices').one('by-collector', chargeValue.collector).get()
                            .then((device) => {
                                return this.RestangularV3.restangularizeElement('', device, 'devices');
                            });
                    },
                    collectorHierarchy: () => {
                        return this.RestangularV3.one('collectors', chargeValue.collector).customGET('hierarchy');
                    }
                }
            } as any);
        };
    }

    angular
        .module('tenantBilling')
        .component('invoicingSummaryContent', {
            templateUrl: 'app/tenantBilling/invoicing/run/components/invoicingSummaryContent/invoicingSummaryContent.html',
            controller: InvoicingSummaryContentCtrl,
            controllerAs: 'vm',
            bindings: {
                monthlyBilling: '<',
                tenantInvoices: '<',
                chargeCategories: '<',
                tenantChargeValues: '<',
                building: '<',
                showCompletionMessage: '<'
            }
        });
}

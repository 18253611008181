angular
    .module('aq.reports')
    .config(function ($stateProvider) {
        $stateProvider.state('aq.reports.portfolio.ytd', {
            templateUrl: 'app/reports/summary/portfolio/portfolioYTD.html',
            controller: 'PortfolioControllerYTD',
            controllerAs: 'vm',
            data: {
                hideNavbar: true
            },
            resolve: {
                waitForAuthToken(allAppsLoaded, authToken) {
                    return authToken;
                },
                options(OptionsService, account) {
                    return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
                },
                energyStarScoreMap(Restangular, account, date) {
                    return Restangular.one('accounts', account.id).customGET('energyStarScores', { date }).then((data) => data, (error) => { });
                },
                reportMonth(date) {
                    return moment(date).subtract(1, 'month').format('MMMM');
                },
                allBuildings(account) {
                    // TODO: retreive all buildings for the account
                    // alternatively, modify buildingGroups endpoint to include info about 'incomplete' groups directly
                    return [];
                },
                buildings(RestangularV3: restangular.IService) {
                    return RestangularV3.one('').getList('buildings', { showInactive: false });
                },
                buildingIds(buildings) {
                    return buildings.map(b => b.id);
                },
                buildingGroups(RestangularV3, allBuildings, buildings) {
                    return RestangularV3.all('building-groups').getList().then((groups) => {
                        _.each(groups, (group) => {
                            const filteredAllBuildings = _.filter(allBuildings, (b) => b.buildingGroup == group.id);
                            const filteredVisibleBuildings = _.filter(buildings, (b) => b.buildingGroup == group.id);
                            if (filteredAllBuildings.length > filteredVisibleBuildings.length) {
                                group.isIncomplete = true;
                            }
                        });
                        return groups;
                    });
                },
                utilitySpendingBudgets($q: ng.IQService, RestangularV3, buildingIds, date) {
                    const start = moment(date).startOf('year').format(`YYYY-MM-DDTHH:mm:ssZ`);
                    const end = moment(date).endOf('year').format(`YYYY-MM-DDTHH:mm:ssZ`);

                    return RestangularV3.one('utility-spending').customGET('for-buildings',
                        {
                            start,
                            end,
                            buildingIds,
                            interval: '1mon',
                            measure: 'electricity'
                        })
                        .catch((err) => []);
                },
                utilitySpendingBudgetsPastYear($q: ng.IQService, RestangularV3, buildings, buildingIds, date) {
                    const isPastDataRequired = _.some(buildings, (building) => {
                        const start = getFiscalYearStart(building, moment(date));
                        return moment(start).year() < moment(date).year();
                    });
                    if (isPastDataRequired) {
                        const pastYearStartDate = moment(`${moment(date).year() - 1}-12-15`).format(`YYYY-MM-DDTHH:mm:ssZ`);
                        const pastYearEndDate = moment(`${moment(date).year()}-12-15`).format(`YYYY-MM-DDTHH:mm:ssZ`);
                        return RestangularV3.one('utility-spending').customGET('for-buildings',
                            {
                                start: pastYearStartDate,
                                end: pastYearEndDate,
                                buildingIds,
                                interval: '1mon',
                                measure: 'electricity'
                            })
                            .catch((err) => []);
                    } else {
                        return;
                    }
                },
                buildingTargets(Restangular, account, buildingIds) {
                    return Restangular.one('accounts', account.id).all('targets')
                        .customGET('aggregate', { inflate: 'targetItems,measure', buildings: buildingIds });
                },
                buildingConsumptions(Restangular, account, buildings, $q: ng.IQService, options, OptionsService, date) {
                    const promises = getMonthlyDataForType(Restangular, account, buildings, date, 'CONSUMPTION');
                    return $q.all(promises);
                },
                buildingPeakDemands(Restangular, account, buildings, $q, $filter: ng.IFilterService, options, OptionsService, date) {
                    const promises = getMonthlyDataForType(Restangular, account, buildings, date, 'PEAK_DEMAND');
                    return $q.all(promises);
                },
                buildingSchedules: (RestangularV3, buildings) => {
                    return RestangularV3.one('calendars').get()
                        .then((data) => {
                            return _.map(buildings, (building) => {
                                return {
                                    buildingId: building.id,
                                    schedule: _.find(data, (item) => item.id == building.calendar)
                                };
                            });
                        }, (error) => {
                            return [];
                        });
                },
                buildingSchedulePartitionedConsumptions(
                    Restangular,
                    account,
                    buildings,
                    $q: ng.IQService,
                    date
                ) {
                    let counter = 0;
                    const batchSize = 5;
                    const defs = [$q.defer()];
                    let result = [];
                    while (buildings.length > 0 && counter < buildings.length) {
                        const nextDefer = $q.defer();
                        const lastDefer = _.last(defs);
                        defs.push(nextDefer);

                        const closure = (cnt, nextDef) => {
                            lastDefer.promise.then(() => {
                                const batchPromises = getNextRequestBatch(Restangular, account, buildings, date, cnt, cnt + batchSize);
                                $q.all(batchPromises).then((data) => {
                                    result = _.concat(result, data);
                                    nextDef.resolve();
                                });
                            });
                        };
                        closure(counter, nextDefer);
                        counter += batchSize;
                    }
                    const finalDefer = _.last(defs);
                    _.first(defs).resolve([]);
                    return finalDefer.promise.then(() => {
                        return result;
                    });
                },
                buildingPersonnels(buildings, $q, RestangularV3) {
                    const promises = _.map(buildings, (building) => {
                        return RestangularV3.one('buildings', building.id).customGET('personnel')
                            .then((result) => {
                                return {
                                    buildingId: building.id,
                                    personnel: result
                                };
                            }, (error) => {
                                return {
                                    buildingId: building.id,
                                    personnel: []
                                };
                            });
                    });
                    return $q.all(promises);
                },
                buildingOccupancy(buildingIds, Restangular, account) {
                    return Restangular.one('accounts', account.id).customGET('building-occupancy', { buildings: buildingIds });
                },
                buildingDegreeDays(Restangular, account, buildings, $q, date) {
                    const promises = _.map(buildings, (building) => {
                        const start = getFiscalYearStart(building, moment(date));
                        const end = moment(date).format();
                        return Restangular.one('accounts', account.id).one('buildings', building.id)
                            .customGET('degreeDays', { start, end, interval: '1mon' })
                            .then((result) => {
                                return {
                                    buildingId: building.id,
                                    degreeDays: result
                                };
                            }, (error) => {
                                return {
                                    buildingId: building.id,
                                    degreeDays: {
                                        hdd: { values: [] },
                                        cdd: { values: [] }
                                    }
                                };
                            });
                    });
                    return $q.all(promises);
                }
            }
        });
        const getNextRequestBatch = (Restangular, account, buildings, date, index, count) => {
            const items = _.slice(buildings, index, count);
            const promises = _.map(items, (building) => {
                const start = moment(date).subtract(1, 'month').tz(building.timeZoneId).startOf('month').format();
                const end = moment(start).endOf('month').format();

                return Restangular.one('accounts', account.id).one('buildings', building.id)
                    .customGET('metrics', {
                        interval: '1h',
                        start,
                        end,
                        partitionId: building.calendar,
                        partitionType: 'workcalendar',
                        measure: 'ENERGY'
                    })
                    .catch(() => {
                        return {
                            id: building.id,
                            start,
                            end,
                            partitions: []
                        };
                    });
            });
            return promises;
        };
        const getMonthlyDataForType = (Restangular, account, buildings, date, type): ng.IPromise<any>[] => {
            let minDate: moment.Moment;
            _.each(buildings, (building) => {
                const start = getFiscalYearStartMoment(building, moment(date));
                if (!minDate || start.isBefore(minDate)) {
                    minDate = start;
                }
            });
            const promises = [];
            const monthDate = moment(minDate);
            while (monthDate.isSameOrBefore(moment(date))) {
                promises.push(Restangular.one('accounts', account.id).one('portfolio')
                    .customGET('insightValues', { type, date: moment(monthDate).valueOf() })
                    .catch(() => { }));
                monthDate.add(1, 'month');
            }
            return promises;
        };
        const getFiscalYearStart = (building, currentDate: moment.Moment) => {
            const currentMonth = currentDate.month() + 1;
            const startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
            let startYear = currentDate.year();
            if (currentMonth <= startMonth) {
                startYear--;
            }
            return getBuildingTimezoneFirstDateOfMonth(building, startYear, startMonth);
        };
        const getFiscalYearStartMoment = (building, currentDate: moment.Moment): moment.Moment => {
            const result = moment(currentDate);
            const startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
            const monthDiff = startMonth - (currentDate.month() + 1);
            if (monthDiff > 0) {
                result.subtract(1, 'year');
            }
            result.add(monthDiff, 'month');
            return result;
        };
        const getBuildingTimezoneFirstDateOfMonth = (building, year, month) => {
            const yearStartString = `${year}-${month < 10 ? 0 : ''}${month}-01T00:00:00Z`;
            const offsetStart = moment(yearStartString).tz(building.timeZoneId).utcOffset();
            const start = moment(yearStartString).tz(building.timeZoneId).add(-offsetStart, 'minutes').format();
            return start;
        };
    });

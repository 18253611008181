namespace aq.utilityBudgets {

    export interface UtilityServiceBillViewModel {
        id: number;
        type: string;
        utilityCompanyName: string;
        utilityCompanyAccount: string;
        usageRealUnitDisplay: string;
        demandRealUnitDisplay: string;
        includeSewer: boolean;
        collectors: any[];
    }

    export interface UtilityBillPeriodChargeView {
        serviceType: string;
        utilityMeter: number;
        collectorName: string;
        meterNumber: string;
        startDate: string;
        endDate: string;
        charge: number;
        demand: number;
        usage: number;
        usageCharge: number;
        demandCharge: number;
        taxCharge: number;
        otherCharge: number;
        adjustmentCharge: number;
        lateFeeCharge: number;
        cost: number;
    }

    export class AddEditSingleBill extends aq.common.Controllers.ModalCtrl {
        public currentUtilityBillingPeriod: UtilityBillPeriod;
        public isFirst: boolean;
        public isLast: boolean;
        public chargeViews: UtilityBillPeriodChargeView[];
        public isLoading: boolean;
        private currencyUnit;
        private collectorMap: { [collectorId: number]: aq.common.models.Collector };
        private utilityMeterMap: { [utilityMeterId: number]: UtilityMeter };
        private urjanetMeterMap: { [meterNumber: string]: UrjanetMeter };
        private DATE_FORMAT: string = 'MM/DD/YYYY';
        private currencyUnitSymbol: string;
        private title: string;
        private existingBillPeriods: DateInterval[];
        private utilityBillPeriodCharges: aq.utilityBudgets.UtilityBillPeriodCharge[];

        constructor(
            protected $mdDialog: ng.material.IDialogService,
            private utilityServiceModel: UtilityServiceBillViewModel,
            private Messages: aq.services.Messages,
            private accountId: string,
            private buildingId: string,
            private UtilityServiceHelper: UtilityServiceHelper,
            private utilityServiceBillPeriods: any[],
            private utilityBillingPeriod: any,
            private RestangularV3: restangular.IService,
            private DataStore: aq.common.DataStore,
            private readOnly: boolean,
            private $q: ng.IQService,
            private isAqAdmin: boolean,
            private collectors: aq.common.models.Collector[],
            private utilityMeters: aq.utilityBudgets.UtilityMeter[],
            private urjanetMeters: aq.utilityBudgets.UrjanetMeter[],
            private $filter: ng.IFilterService,
            private loading: aq.services.Loading,
            private Segment: aq.services.SegmentService,
            protected $scope: ng.IScope
        ) {
            super($scope, $mdDialog);
            this.collectorMap = _.keyBy(this.collectors, 'id');
            this.utilityMeterMap = _.keyBy(this.utilityMeters, 'id');
            this.urjanetMeterMap = _.keyBy(this.urjanetMeters, 'meterNumber');
            this.currencyUnit = this.UtilityServiceHelper.getCurrencyUnit();
            this.currencyUnitSymbol = this.UtilityServiceHelper.getCurrencyUnitSymbol();

            this.init(this.utilityBillingPeriod);

            const currentIndex = this.getCurrentBillIndex();
            this.setIsFirstLastBill(currentIndex);
        }

        public init(utilityBillingPeriod) {
            this.existingBillPeriods = this.getExistingBillPeriods(utilityBillingPeriod.id);
            this.currentUtilityBillingPeriod = angular.copy(utilityBillingPeriod);
            this.transformBillPeriodForView(this.currentUtilityBillingPeriod);
            // Also make readonly if this bill came from an urjanet statement
            this.readOnly = this.readOnly || !!(this.currentUtilityBillingPeriod && this.currentUtilityBillingPeriod.utilityBillStatementId);
            const start = moment(this.currentUtilityBillingPeriod.startDateDisplay).format(this.DATE_FORMAT);
            const end = moment(this.currentUtilityBillingPeriod.endDate).format(this.DATE_FORMAT);
            this.title = `${this.utilityServiceModel.utilityCompanyName} (${start} - ${end})`;

            this.initPeriodCharges(utilityBillingPeriod.id);
        }

        public initPeriodCharges(id) {
            if (id) {
                this.isLoading = true;
                this.loading.start(true);
                this.RestangularV3.all('utility-bill-period-charges')
                    .getList({
                        utilityBillPeriodId: id
                    })
                    .then((result) => {
                        this.utilityBillPeriodCharges = result;
                        this.chargeViews = this.initializeChargeViews();
                    })
                    .finally(() => {
                        this.loading.stop();
                        this.isLoading = false;
                    });
            } else {
                this.chargeViews = [];
            }
        }

        public getExistingBillPeriods(excludeBillPeriodId: number): aq.utilityBudgets.DateInterval[] {
            const billPeriods = excludeBillPeriodId
                ? _.filter(this.utilityServiceBillPeriods, (billPeriod) => billPeriod.id != excludeBillPeriodId)
                : this.utilityServiceBillPeriods;
            return _.map(billPeriods, (billPeriod) => <aq.utilityBudgets.DateInterval>{
                startDate: billPeriod.startDate,
                endDate: billPeriod.endDate
            });
        }

        public openPreviousBill() {
            this.getConfirmationIfRequired().then(() => {
                this.$scope.billPeriodForm.$setPristine();
                this.openBillWithOffset(-1);
            });
        }

        public openNextBill() {
            this.getConfirmationIfRequired().then(() => {
                this.$scope.billPeriodForm.$setPristine();
                this.openBillWithOffset(1);
            });
        }

        public getConfirmationIfRequired() {
            if (this.$scope.billPeriodForm.$dirty) {
                var confirm = this.$mdDialog.confirm()
                    .title('It looks like you have unsaved changes')
                    .textContent('If you leave before saving, your changes will be lost.')
                    .ariaLabel('Dirty form')
                    .ok('Leave')
                    .cancel('Stay') as any;
                (confirm as any).multiple(true);

                return this.$mdDialog.show(confirm);
            }
            return this.$q.when();
        }

        public openBillWithOffset(offset: number) {
            const currentIndex = this.getCurrentBillIndex();
            if (currentIndex === -1) {
                return;
            }
            const nextIndex = currentIndex + offset;
            if (nextIndex >= 0 && nextIndex < this.utilityServiceBillPeriods.length) {
                this.init(this.utilityServiceBillPeriods[nextIndex]);
            }
            this.setIsFirstLastBill(nextIndex);
        }

        public getCurrentBillIndex() {
            return _.findIndex(this.utilityServiceBillPeriods, (item) => item.id === this.currentUtilityBillingPeriod.id);
        }

        public setIsFirstLastBill(nextIndex) {
            this.isFirst = nextIndex === 0;
            this.isLast = nextIndex === this.utilityServiceBillPeriods.length - 1;
        }

        public savePeriod() {
            this.loading.start(true);
            let copy: any = angular.copy(this.currentUtilityBillingPeriod);
            copy = this.transformForRequest(copy);
            if (this.currentUtilityBillingPeriod.id) {
                copy = this.RestangularV3.restangularizeElement('', copy, 'utility-bill-periods');
                return copy.put()
                    .then((result) => {
                        this.hide(this.currentUtilityBillingPeriod);
                        this.Messages.success('Successfully saved period');
                        return result;
                    })
                    .finally(this.loading.stop);
            } else {
                this.Segment.trackEvent('Utility Accounts:Manual Bill Upload');
                return this.DataStore.create(this.RestangularV3.all('utility-bill-periods'), copy, { buildingId: this.buildingId })
                    .then((result) => {
                        this.currentUtilityBillingPeriod.id = result.id;
                        this.hide(this.currentUtilityBillingPeriod);
                        this.Messages.success('Successfully saved period');
                        return result;
                    })
                    .finally(this.loading.stop);
            }
        }

        public transformForRequest(copy) {
            copy = this.RestangularV3.stripRestangular(copy);
            delete copy.originalElement;
            delete copy.startDateDisplay;
            if (copy.service && copy.service.id) {
                copy.service = copy.service.id;
            }
            copy.startDate = moment(copy.startDate).format('YYYY-MM-DD');
            copy.endDate = moment(copy.endDate).format('YYYY-MM-DD');
            copy.invoiceDate = copy.invoiceDate ? moment(copy.invoiceDate).format('YYYY-MM-DD') : null;
            return copy;
        }

        public cancel(): void {
            this.getConfirmationIfRequired().then(() => {
                this.$mdDialog.cancel();
            });
        }

        public hide(data): void {
            this.$mdDialog.hide(data);
        }

        public shouldShowServiceType(serviceType: string): boolean {
            return serviceType === 'water' || serviceType === 'sewer';
        }

        public shouldIgnoreCharge(charge: UtilityBillPeriodChargeView): boolean {
            return this.currentUtilityBillingPeriod.utilityBillStatementId
                && this.utilityServiceModel.type == 'WATER'
                && !this.utilityServiceModel.includeSewer
                && charge.serviceType === 'sewer';
        }

        public isBillPeriodOverlapping() {
            if (!this.currentUtilityBillingPeriod.startDate || !this.currentUtilityBillingPeriod.endDate) {
                return false;
            }
            const currentStartDate = this.currentUtilityBillingPeriod.startDate;
            const currentEndDate = this.currentUtilityBillingPeriod.endDate;
            const currentInterval = {
                startDate: currentStartDate,
                endDate: currentEndDate
            };
            const isOverlapping = _.some(this.existingBillPeriods, (period: DateInterval) => {
                return this.overlap([currentInterval, period]);
            });
            return isOverlapping;
        }

        public transformBillPeriodForView(billPeriod) {
            if (!billPeriod) {
                return;
            }
            if (billPeriod.invoiceDate) {
                billPeriod.invoiceDate = moment(billPeriod.invoiceDate).toDate();
            }
            if (billPeriod.startDate) {
                billPeriod.startDate = moment(billPeriod.startDate).toDate();
            }
            if (billPeriod.endDate) {
                billPeriod.endDate = moment(billPeriod.endDate).toDate();
            }
            return billPeriod;
        }

        public checkForMatchingUnits(statementUsageUnit, defaultUnit) {
            defaultUnit = defaultUnit.toLowerCase();
            if (statementUsageUnit != null) {
                statementUsageUnit = statementUsageUnit.toLowerCase();
                if (statementUsageUnit == 'gallons') {
                    statementUsageUnit = 'gal';
                } else if (statementUsageUnit == 'hcf') {
                    statementUsageUnit = 'ccf';

                }
            }
            return statementUsageUnit == defaultUnit;
        }

        // TODO update in in AQ-10448
        public initializeChargeViews() {
            const chargeViews = [];
            const seenUtilityMeters: number[] = _.map(chargeViews, 'utilityMeter');
            _.each(this.utilityBillPeriodCharges, (billCharge: UtilityBillPeriodCharge) => {
                if (!_.includes(seenUtilityMeters, billCharge.utilityMeter)) {
                    chargeViews.push(this.getChargeViewFromUtilityBillPeriodCharge(billCharge));
                }
            });
            return chargeViews;
        }
        /*************************/

        public validateTotals(utilityBillingPeriod: UtilityBillPeriod): boolean {
            if (utilityBillingPeriod.usageCharge == null && utilityBillingPeriod.demandCharge == null
                && utilityBillingPeriod.taxCharge == null && utilityBillingPeriod.otherCharge == null) {
                return true;
            }
            return (utilityBillingPeriod.charge ? utilityBillingPeriod.charge : 0) === this.getSum(utilityBillingPeriod);
        }

        public getSum(utilityBillingPeriod: UtilityBillPeriod): number {
            const demandCharge = this.isDemandVisible() && utilityBillingPeriod.demandCharge != null ? utilityBillingPeriod.demandCharge : 0;
            const usageCharge = utilityBillingPeriod.usageCharge != null ? utilityBillingPeriod.usageCharge : 0;
            const taxCharge = utilityBillingPeriod.taxCharge != null ? utilityBillingPeriod.taxCharge : 0;
            const otherCharge = utilityBillingPeriod.otherCharge != null ? utilityBillingPeriod.otherCharge : 0;
            return usageCharge + demandCharge + taxCharge + otherCharge;
        }

        public validateCostTotal(utilityBillPeriod: UtilityBillPeriod): boolean {
            if (utilityBillPeriod.charge == null && utilityBillPeriod.adjustmentCharge == null && utilityBillPeriod.lateFeeCharge == null) {
                return true;
            }
            return (utilityBillPeriod.cost ? utilityBillPeriod.cost : 0) === this.getCostSum(utilityBillPeriod);

        }

        public getCostSum(utilityBillingPeriod: UtilityBillPeriod): number {
            const adjustment = utilityBillingPeriod.adjustmentCharge != null ? utilityBillingPeriod.adjustmentCharge : 0;
            const lateFee = utilityBillingPeriod.lateFeeCharge != null ? utilityBillingPeriod.lateFeeCharge : 0;
            const charge = utilityBillingPeriod.charge != null ? utilityBillingPeriod.charge : 0;
            return adjustment + lateFee + charge;
        }

        public getChargeTotal(utilityBillingPeriod: UtilityBillPeriod): number {
            return utilityBillingPeriod.charge != null ? utilityBillingPeriod.charge : 0;
        }

        private getUtilityMeterforMeterStatement(meter): UtilityMeter {
            if (!meter.meterNumber) {
                return null;
            }
            const urjanetMeter: UrjanetMeter = this.urjanetMeterMap[meter.meterNumber];
            if (!urjanetMeter) {
                return null;
            }
            return _.find(this.utilityMeters, { urjanetMeter: urjanetMeter.id });
        }

        private getChargeViewFromUtilityBillPeriodCharge(billCharge: UtilityBillPeriodCharge) {
            const charge: UtilityBillPeriodChargeView = {
                serviceType: null,
                utilityMeter: null,
                collectorName: 'Unmatched',
                meterNumber: billCharge.utilityProviderMeterName,
                startDate: moment(billCharge.startDate).format(this.DATE_FORMAT),
                endDate: moment(billCharge.endDate).format(this.DATE_FORMAT),
                charge: billCharge.charge,
                usage: billCharge.usage
                    ? this.$filter<Function>('formatUsage')(billCharge.usage, this.utilityServiceModel.usageRealUnitDisplay)
                    : null,
                demand: billCharge.demand ?
                    this.$filter<Function>('formatUsage')(billCharge.demand, this.utilityServiceModel.demandRealUnitDisplay)
                    : null,
                usageCharge: billCharge.usageCharge,
                demandCharge: billCharge.demandCharge,
                taxCharge: billCharge.taxCharge,
                otherCharge: billCharge.otherCharge,
                adjustmentCharge: billCharge.adjustmentCharge,
                cost: billCharge.cost,
                lateFeeCharge: billCharge.lateFeeCharge
            };
            const utilityMeter: UtilityMeter = this.utilityMeterMap[billCharge.utilityMeter];
            if (utilityMeter) {
                charge.utilityMeter = Number(utilityMeter.id);
                const urjanetMeter = this.urjanetMeters.find(urjanetMeter => String(urjanetMeter.id) === utilityMeter.utilityProviderMeterId);
                if (urjanetMeter) {
                    charge.serviceType = urjanetMeter.serviceType
                }
                const collector: aq.common.models.Collector = this.collectorMap[utilityMeter.collector];
                if (collector) {
                    charge.collectorName = collector.name;
                } else {
                    charge.collectorName = 'Meter from another building';
                }
            }
            return charge;
        }

        private overlap(dateRanges): boolean {
            const sortedRanges = dateRanges.sort((previous, current) => {

                // get the start date from previous and current
                const previousTime = moment(previous.startDate).toDate().getTime();
                const currentTime = moment(current.startDate).toDate().getTime();

                // if the previous is earlier than the current
                if (previousTime < currentTime) {
                    return -1;
                }

                // if the previous time is the same as the current time
                if (previousTime === currentTime) {
                    return 0;
                }

                // if the previous time is later than the current time
                return 1;
            });

            const result = sortedRanges.reduce((innerResult, current, idx, arr) => {
                // get the previous range
                if (idx === 0) { return result; }
                const previous = arr[idx - 1];

                // check for any overlap
                const previousEnd = moment(previous.endDate).toDate().getTime();
                const currentStart = moment(current.startDate).toDate().getTime();
                const overlap = (previousEnd > currentStart);

                if (overlap) {
                    innerResult = true;
                }

                return innerResult;
            }, false);

            return result;
        }

        private isDemandVisible(): boolean {
            return this.utilityServiceModel.type.toLowerCase() === 'electricity';
        }
    }

    angular.module('aq.utilityBudgets').controller('AddEditSingleBill', AddEditSingleBill);
}

var aq;
(function (aq) {
    var utilityBudgets;
    (function (utilityBudgets) {
        var AddEditSingleBill = /** @class */ (function (_super) {
            __extends(AddEditSingleBill, _super);
            function AddEditSingleBill($mdDialog, utilityServiceModel, Messages, accountId, buildingId, UtilityServiceHelper, utilityServiceBillPeriods, utilityBillingPeriod, RestangularV3, DataStore, readOnly, $q, isAqAdmin, collectors, utilityMeters, urjanetMeters, $filter, loading, Segment, $scope) {
                var _this = _super.call(this, $scope, $mdDialog) || this;
                _this.$mdDialog = $mdDialog;
                _this.utilityServiceModel = utilityServiceModel;
                _this.Messages = Messages;
                _this.accountId = accountId;
                _this.buildingId = buildingId;
                _this.UtilityServiceHelper = UtilityServiceHelper;
                _this.utilityServiceBillPeriods = utilityServiceBillPeriods;
                _this.utilityBillingPeriod = utilityBillingPeriod;
                _this.RestangularV3 = RestangularV3;
                _this.DataStore = DataStore;
                _this.readOnly = readOnly;
                _this.$q = $q;
                _this.isAqAdmin = isAqAdmin;
                _this.collectors = collectors;
                _this.utilityMeters = utilityMeters;
                _this.urjanetMeters = urjanetMeters;
                _this.$filter = $filter;
                _this.loading = loading;
                _this.Segment = Segment;
                _this.$scope = $scope;
                _this.DATE_FORMAT = 'MM/DD/YYYY';
                _this.collectorMap = _.keyBy(_this.collectors, 'id');
                _this.utilityMeterMap = _.keyBy(_this.utilityMeters, 'id');
                _this.urjanetMeterMap = _.keyBy(_this.urjanetMeters, 'meterNumber');
                _this.currencyUnit = _this.UtilityServiceHelper.getCurrencyUnit();
                _this.currencyUnitSymbol = _this.UtilityServiceHelper.getCurrencyUnitSymbol();
                _this.init(_this.utilityBillingPeriod);
                var currentIndex = _this.getCurrentBillIndex();
                _this.setIsFirstLastBill(currentIndex);
                return _this;
            }
            AddEditSingleBill.prototype.init = function (utilityBillingPeriod) {
                this.existingBillPeriods = this.getExistingBillPeriods(utilityBillingPeriod.id);
                this.currentUtilityBillingPeriod = angular.copy(utilityBillingPeriod);
                this.transformBillPeriodForView(this.currentUtilityBillingPeriod);
                // Also make readonly if this bill came from an urjanet statement
                this.readOnly = this.readOnly || !!(this.currentUtilityBillingPeriod && this.currentUtilityBillingPeriod.utilityBillStatementId);
                var start = moment(this.currentUtilityBillingPeriod.startDateDisplay).format(this.DATE_FORMAT);
                var end = moment(this.currentUtilityBillingPeriod.endDate).format(this.DATE_FORMAT);
                this.title = this.utilityServiceModel.utilityCompanyName + " (" + start + " - " + end + ")";
                this.initPeriodCharges(utilityBillingPeriod.id);
            };
            AddEditSingleBill.prototype.initPeriodCharges = function (id) {
                var _this = this;
                if (id) {
                    this.isLoading = true;
                    this.loading.start(true);
                    this.RestangularV3.all('utility-bill-period-charges')
                        .getList({
                        utilityBillPeriodId: id
                    })
                        .then(function (result) {
                        _this.utilityBillPeriodCharges = result;
                        _this.chargeViews = _this.initializeChargeViews();
                    })
                        .finally(function () {
                        _this.loading.stop();
                        _this.isLoading = false;
                    });
                }
                else {
                    this.chargeViews = [];
                }
            };
            AddEditSingleBill.prototype.getExistingBillPeriods = function (excludeBillPeriodId) {
                var billPeriods = excludeBillPeriodId
                    ? _.filter(this.utilityServiceBillPeriods, function (billPeriod) { return billPeriod.id != excludeBillPeriodId; })
                    : this.utilityServiceBillPeriods;
                return _.map(billPeriods, function (billPeriod) { return ({
                    startDate: billPeriod.startDate,
                    endDate: billPeriod.endDate
                }); });
            };
            AddEditSingleBill.prototype.openPreviousBill = function () {
                var _this = this;
                this.getConfirmationIfRequired().then(function () {
                    _this.$scope.billPeriodForm.$setPristine();
                    _this.openBillWithOffset(-1);
                });
            };
            AddEditSingleBill.prototype.openNextBill = function () {
                var _this = this;
                this.getConfirmationIfRequired().then(function () {
                    _this.$scope.billPeriodForm.$setPristine();
                    _this.openBillWithOffset(1);
                });
            };
            AddEditSingleBill.prototype.getConfirmationIfRequired = function () {
                if (this.$scope.billPeriodForm.$dirty) {
                    var confirm = this.$mdDialog.confirm()
                        .title('It looks like you have unsaved changes')
                        .textContent('If you leave before saving, your changes will be lost.')
                        .ariaLabel('Dirty form')
                        .ok('Leave')
                        .cancel('Stay');
                    confirm.multiple(true);
                    return this.$mdDialog.show(confirm);
                }
                return this.$q.when();
            };
            AddEditSingleBill.prototype.openBillWithOffset = function (offset) {
                var currentIndex = this.getCurrentBillIndex();
                if (currentIndex === -1) {
                    return;
                }
                var nextIndex = currentIndex + offset;
                if (nextIndex >= 0 && nextIndex < this.utilityServiceBillPeriods.length) {
                    this.init(this.utilityServiceBillPeriods[nextIndex]);
                }
                this.setIsFirstLastBill(nextIndex);
            };
            AddEditSingleBill.prototype.getCurrentBillIndex = function () {
                var _this = this;
                return _.findIndex(this.utilityServiceBillPeriods, function (item) { return item.id === _this.currentUtilityBillingPeriod.id; });
            };
            AddEditSingleBill.prototype.setIsFirstLastBill = function (nextIndex) {
                this.isFirst = nextIndex === 0;
                this.isLast = nextIndex === this.utilityServiceBillPeriods.length - 1;
            };
            AddEditSingleBill.prototype.savePeriod = function () {
                var _this = this;
                this.loading.start(true);
                var copy = angular.copy(this.currentUtilityBillingPeriod);
                copy = this.transformForRequest(copy);
                if (this.currentUtilityBillingPeriod.id) {
                    copy = this.RestangularV3.restangularizeElement('', copy, 'utility-bill-periods');
                    return copy.put()
                        .then(function (result) {
                        _this.hide(_this.currentUtilityBillingPeriod);
                        _this.Messages.success('Successfully saved period');
                        return result;
                    })
                        .finally(this.loading.stop);
                }
                else {
                    this.Segment.trackEvent('Utility Accounts:Manual Bill Upload');
                    return this.DataStore.create(this.RestangularV3.all('utility-bill-periods'), copy, { buildingId: this.buildingId })
                        .then(function (result) {
                        _this.currentUtilityBillingPeriod.id = result.id;
                        _this.hide(_this.currentUtilityBillingPeriod);
                        _this.Messages.success('Successfully saved period');
                        return result;
                    })
                        .finally(this.loading.stop);
                }
            };
            AddEditSingleBill.prototype.transformForRequest = function (copy) {
                copy = this.RestangularV3.stripRestangular(copy);
                delete copy.originalElement;
                delete copy.startDateDisplay;
                if (copy.service && copy.service.id) {
                    copy.service = copy.service.id;
                }
                copy.startDate = moment(copy.startDate).format('YYYY-MM-DD');
                copy.endDate = moment(copy.endDate).format('YYYY-MM-DD');
                copy.invoiceDate = copy.invoiceDate ? moment(copy.invoiceDate).format('YYYY-MM-DD') : null;
                return copy;
            };
            AddEditSingleBill.prototype.cancel = function () {
                var _this = this;
                this.getConfirmationIfRequired().then(function () {
                    _this.$mdDialog.cancel();
                });
            };
            AddEditSingleBill.prototype.hide = function (data) {
                this.$mdDialog.hide(data);
            };
            AddEditSingleBill.prototype.shouldShowServiceType = function (serviceType) {
                return serviceType === 'water' || serviceType === 'sewer';
            };
            AddEditSingleBill.prototype.shouldIgnoreCharge = function (charge) {
                return this.currentUtilityBillingPeriod.utilityBillStatementId
                    && this.utilityServiceModel.type == 'WATER'
                    && !this.utilityServiceModel.includeSewer
                    && charge.serviceType === 'sewer';
            };
            AddEditSingleBill.prototype.isBillPeriodOverlapping = function () {
                var _this = this;
                if (!this.currentUtilityBillingPeriod.startDate || !this.currentUtilityBillingPeriod.endDate) {
                    return false;
                }
                var currentStartDate = this.currentUtilityBillingPeriod.startDate;
                var currentEndDate = this.currentUtilityBillingPeriod.endDate;
                var currentInterval = {
                    startDate: currentStartDate,
                    endDate: currentEndDate
                };
                var isOverlapping = _.some(this.existingBillPeriods, function (period) {
                    return _this.overlap([currentInterval, period]);
                });
                return isOverlapping;
            };
            AddEditSingleBill.prototype.transformBillPeriodForView = function (billPeriod) {
                if (!billPeriod) {
                    return;
                }
                if (billPeriod.invoiceDate) {
                    billPeriod.invoiceDate = moment(billPeriod.invoiceDate).toDate();
                }
                if (billPeriod.startDate) {
                    billPeriod.startDate = moment(billPeriod.startDate).toDate();
                }
                if (billPeriod.endDate) {
                    billPeriod.endDate = moment(billPeriod.endDate).toDate();
                }
                return billPeriod;
            };
            AddEditSingleBill.prototype.checkForMatchingUnits = function (statementUsageUnit, defaultUnit) {
                defaultUnit = defaultUnit.toLowerCase();
                if (statementUsageUnit != null) {
                    statementUsageUnit = statementUsageUnit.toLowerCase();
                    if (statementUsageUnit == 'gallons') {
                        statementUsageUnit = 'gal';
                    }
                    else if (statementUsageUnit == 'hcf') {
                        statementUsageUnit = 'ccf';
                    }
                }
                return statementUsageUnit == defaultUnit;
            };
            // TODO update in in AQ-10448
            AddEditSingleBill.prototype.initializeChargeViews = function () {
                var _this = this;
                var chargeViews = [];
                var seenUtilityMeters = _.map(chargeViews, 'utilityMeter');
                _.each(this.utilityBillPeriodCharges, function (billCharge) {
                    if (!_.includes(seenUtilityMeters, billCharge.utilityMeter)) {
                        chargeViews.push(_this.getChargeViewFromUtilityBillPeriodCharge(billCharge));
                    }
                });
                return chargeViews;
            };
            /*************************/
            AddEditSingleBill.prototype.validateTotals = function (utilityBillingPeriod) {
                if (utilityBillingPeriod.usageCharge == null && utilityBillingPeriod.demandCharge == null
                    && utilityBillingPeriod.taxCharge == null && utilityBillingPeriod.otherCharge == null) {
                    return true;
                }
                return (utilityBillingPeriod.charge ? utilityBillingPeriod.charge : 0) === this.getSum(utilityBillingPeriod);
            };
            AddEditSingleBill.prototype.getSum = function (utilityBillingPeriod) {
                var demandCharge = this.isDemandVisible() && utilityBillingPeriod.demandCharge != null ? utilityBillingPeriod.demandCharge : 0;
                var usageCharge = utilityBillingPeriod.usageCharge != null ? utilityBillingPeriod.usageCharge : 0;
                var taxCharge = utilityBillingPeriod.taxCharge != null ? utilityBillingPeriod.taxCharge : 0;
                var otherCharge = utilityBillingPeriod.otherCharge != null ? utilityBillingPeriod.otherCharge : 0;
                return usageCharge + demandCharge + taxCharge + otherCharge;
            };
            AddEditSingleBill.prototype.validateCostTotal = function (utilityBillPeriod) {
                if (utilityBillPeriod.charge == null && utilityBillPeriod.adjustmentCharge == null && utilityBillPeriod.lateFeeCharge == null) {
                    return true;
                }
                return (utilityBillPeriod.cost ? utilityBillPeriod.cost : 0) === this.getCostSum(utilityBillPeriod);
            };
            AddEditSingleBill.prototype.getCostSum = function (utilityBillingPeriod) {
                var adjustment = utilityBillingPeriod.adjustmentCharge != null ? utilityBillingPeriod.adjustmentCharge : 0;
                var lateFee = utilityBillingPeriod.lateFeeCharge != null ? utilityBillingPeriod.lateFeeCharge : 0;
                var charge = utilityBillingPeriod.charge != null ? utilityBillingPeriod.charge : 0;
                return adjustment + lateFee + charge;
            };
            AddEditSingleBill.prototype.getChargeTotal = function (utilityBillingPeriod) {
                return utilityBillingPeriod.charge != null ? utilityBillingPeriod.charge : 0;
            };
            AddEditSingleBill.prototype.getUtilityMeterforMeterStatement = function (meter) {
                if (!meter.meterNumber) {
                    return null;
                }
                var urjanetMeter = this.urjanetMeterMap[meter.meterNumber];
                if (!urjanetMeter) {
                    return null;
                }
                return _.find(this.utilityMeters, { urjanetMeter: urjanetMeter.id });
            };
            AddEditSingleBill.prototype.getChargeViewFromUtilityBillPeriodCharge = function (billCharge) {
                var charge = {
                    serviceType: null,
                    utilityMeter: null,
                    collectorName: 'Unmatched',
                    meterNumber: billCharge.utilityProviderMeterName,
                    startDate: moment(billCharge.startDate).format(this.DATE_FORMAT),
                    endDate: moment(billCharge.endDate).format(this.DATE_FORMAT),
                    charge: billCharge.charge,
                    usage: billCharge.usage
                        ? this.$filter('formatUsage')(billCharge.usage, this.utilityServiceModel.usageRealUnitDisplay)
                        : null,
                    demand: billCharge.demand ?
                        this.$filter('formatUsage')(billCharge.demand, this.utilityServiceModel.demandRealUnitDisplay)
                        : null,
                    usageCharge: billCharge.usageCharge,
                    demandCharge: billCharge.demandCharge,
                    taxCharge: billCharge.taxCharge,
                    otherCharge: billCharge.otherCharge,
                    adjustmentCharge: billCharge.adjustmentCharge,
                    cost: billCharge.cost,
                    lateFeeCharge: billCharge.lateFeeCharge
                };
                var utilityMeter = this.utilityMeterMap[billCharge.utilityMeter];
                if (utilityMeter) {
                    charge.utilityMeter = Number(utilityMeter.id);
                    var urjanetMeter = this.urjanetMeters.find(function (urjanetMeter) { return String(urjanetMeter.id) === utilityMeter.utilityProviderMeterId; });
                    if (urjanetMeter) {
                        charge.serviceType = urjanetMeter.serviceType;
                    }
                    var collector = this.collectorMap[utilityMeter.collector];
                    if (collector) {
                        charge.collectorName = collector.name;
                    }
                    else {
                        charge.collectorName = 'Meter from another building';
                    }
                }
                return charge;
            };
            AddEditSingleBill.prototype.overlap = function (dateRanges) {
                var sortedRanges = dateRanges.sort(function (previous, current) {
                    // get the start date from previous and current
                    var previousTime = moment(previous.startDate).toDate().getTime();
                    var currentTime = moment(current.startDate).toDate().getTime();
                    // if the previous is earlier than the current
                    if (previousTime < currentTime) {
                        return -1;
                    }
                    // if the previous time is the same as the current time
                    if (previousTime === currentTime) {
                        return 0;
                    }
                    // if the previous time is later than the current time
                    return 1;
                });
                var result = sortedRanges.reduce(function (innerResult, current, idx, arr) {
                    // get the previous range
                    if (idx === 0) {
                        return result;
                    }
                    var previous = arr[idx - 1];
                    // check for any overlap
                    var previousEnd = moment(previous.endDate).toDate().getTime();
                    var currentStart = moment(current.startDate).toDate().getTime();
                    var overlap = (previousEnd > currentStart);
                    if (overlap) {
                        innerResult = true;
                    }
                    return innerResult;
                }, false);
                return result;
            };
            AddEditSingleBill.prototype.isDemandVisible = function () {
                return this.utilityServiceModel.type.toLowerCase() === 'electricity';
            };
            return AddEditSingleBill;
        }(aq.common.Controllers.ModalCtrl));
        utilityBudgets.AddEditSingleBill = AddEditSingleBill;
        angular.module('aq.utilityBudgets').controller('AddEditSingleBill', AddEditSingleBill);
    })(utilityBudgets = aq.utilityBudgets || (aq.utilityBudgets = {}));
})(aq || (aq = {}));

angular
    .module('aq.reports')
    .config(function ($stateProvider) {
    $stateProvider.state('aq.reports.portfolio.ytd', {
        templateUrl: 'app/reports/summary/portfolio/portfolioYTD.html',
        controller: 'PortfolioControllerYTD',
        controllerAs: 'vm',
        data: {
            hideNavbar: true
        },
        resolve: {
            waitForAuthToken: function (allAppsLoaded, authToken) {
                return authToken;
            },
            options: function (OptionsService, account) {
                return OptionsService.init(account.id, account.measurementSystem, account.currencyUnit);
            },
            energyStarScoreMap: function (Restangular, account, date) {
                return Restangular.one('accounts', account.id).customGET('energyStarScores', { date: date }).then(function (data) { return data; }, function (error) { });
            },
            reportMonth: function (date) {
                return moment(date).subtract(1, 'month').format('MMMM');
            },
            allBuildings: function (account) {
                // TODO: retreive all buildings for the account
                // alternatively, modify buildingGroups endpoint to include info about 'incomplete' groups directly
                return [];
            },
            buildings: function (RestangularV3) {
                return RestangularV3.one('').getList('buildings', { showInactive: false });
            },
            buildingIds: function (buildings) {
                return buildings.map(function (b) { return b.id; });
            },
            buildingGroups: function (RestangularV3, allBuildings, buildings) {
                return RestangularV3.all('building-groups').getList().then(function (groups) {
                    _.each(groups, function (group) {
                        var filteredAllBuildings = _.filter(allBuildings, function (b) { return b.buildingGroup == group.id; });
                        var filteredVisibleBuildings = _.filter(buildings, function (b) { return b.buildingGroup == group.id; });
                        if (filteredAllBuildings.length > filteredVisibleBuildings.length) {
                            group.isIncomplete = true;
                        }
                    });
                    return groups;
                });
            },
            utilitySpendingBudgets: function ($q, RestangularV3, buildingIds, date) {
                var start = moment(date).startOf('year').format("YYYY-MM-DDTHH:mm:ssZ");
                var end = moment(date).endOf('year').format("YYYY-MM-DDTHH:mm:ssZ");
                return RestangularV3.one('utility-spending').customGET('for-buildings', {
                    start: start,
                    end: end,
                    buildingIds: buildingIds,
                    interval: '1mon',
                    measure: 'electricity'
                })
                    .catch(function (err) { return []; });
            },
            utilitySpendingBudgetsPastYear: function ($q, RestangularV3, buildings, buildingIds, date) {
                var isPastDataRequired = _.some(buildings, function (building) {
                    var start = getFiscalYearStart(building, moment(date));
                    return moment(start).year() < moment(date).year();
                });
                if (isPastDataRequired) {
                    var pastYearStartDate = moment(moment(date).year() - 1 + "-12-15").format("YYYY-MM-DDTHH:mm:ssZ");
                    var pastYearEndDate = moment(moment(date).year() + "-12-15").format("YYYY-MM-DDTHH:mm:ssZ");
                    return RestangularV3.one('utility-spending').customGET('for-buildings', {
                        start: pastYearStartDate,
                        end: pastYearEndDate,
                        buildingIds: buildingIds,
                        interval: '1mon',
                        measure: 'electricity'
                    })
                        .catch(function (err) { return []; });
                }
                else {
                    return;
                }
            },
            buildingTargets: function (Restangular, account, buildingIds) {
                return Restangular.one('accounts', account.id).all('targets')
                    .customGET('aggregate', { inflate: 'targetItems,measure', buildings: buildingIds });
            },
            buildingConsumptions: function (Restangular, account, buildings, $q, options, OptionsService, date) {
                var promises = getMonthlyDataForType(Restangular, account, buildings, date, 'CONSUMPTION');
                return $q.all(promises);
            },
            buildingPeakDemands: function (Restangular, account, buildings, $q, $filter, options, OptionsService, date) {
                var promises = getMonthlyDataForType(Restangular, account, buildings, date, 'PEAK_DEMAND');
                return $q.all(promises);
            },
            buildingSchedules: function (RestangularV3, buildings) {
                return RestangularV3.one('calendars').get()
                    .then(function (data) {
                    return _.map(buildings, function (building) {
                        return {
                            buildingId: building.id,
                            schedule: _.find(data, function (item) { return item.id == building.calendar; })
                        };
                    });
                }, function (error) {
                    return [];
                });
            },
            buildingSchedulePartitionedConsumptions: function (Restangular, account, buildings, $q, date) {
                var counter = 0;
                var batchSize = 5;
                var defs = [$q.defer()];
                var result = [];
                var _loop_1 = function () {
                    var nextDefer = $q.defer();
                    var lastDefer = _.last(defs);
                    defs.push(nextDefer);
                    var closure = function (cnt, nextDef) {
                        lastDefer.promise.then(function () {
                            var batchPromises = getNextRequestBatch(Restangular, account, buildings, date, cnt, cnt + batchSize);
                            $q.all(batchPromises).then(function (data) {
                                result = _.concat(result, data);
                                nextDef.resolve();
                            });
                        });
                    };
                    closure(counter, nextDefer);
                    counter += batchSize;
                };
                while (buildings.length > 0 && counter < buildings.length) {
                    _loop_1();
                }
                var finalDefer = _.last(defs);
                _.first(defs).resolve([]);
                return finalDefer.promise.then(function () {
                    return result;
                });
            },
            buildingPersonnels: function (buildings, $q, RestangularV3) {
                var promises = _.map(buildings, function (building) {
                    return RestangularV3.one('buildings', building.id).customGET('personnel')
                        .then(function (result) {
                        return {
                            buildingId: building.id,
                            personnel: result
                        };
                    }, function (error) {
                        return {
                            buildingId: building.id,
                            personnel: []
                        };
                    });
                });
                return $q.all(promises);
            },
            buildingOccupancy: function (buildingIds, Restangular, account) {
                return Restangular.one('accounts', account.id).customGET('building-occupancy', { buildings: buildingIds });
            },
            buildingDegreeDays: function (Restangular, account, buildings, $q, date) {
                var promises = _.map(buildings, function (building) {
                    var start = getFiscalYearStart(building, moment(date));
                    var end = moment(date).format();
                    return Restangular.one('accounts', account.id).one('buildings', building.id)
                        .customGET('degreeDays', { start: start, end: end, interval: '1mon' })
                        .then(function (result) {
                        return {
                            buildingId: building.id,
                            degreeDays: result
                        };
                    }, function (error) {
                        return {
                            buildingId: building.id,
                            degreeDays: {
                                hdd: { values: [] },
                                cdd: { values: [] }
                            }
                        };
                    });
                });
                return $q.all(promises);
            }
        }
    });
    var getNextRequestBatch = function (Restangular, account, buildings, date, index, count) {
        var items = _.slice(buildings, index, count);
        var promises = _.map(items, function (building) {
            var start = moment(date).subtract(1, 'month').tz(building.timeZoneId).startOf('month').format();
            var end = moment(start).endOf('month').format();
            return Restangular.one('accounts', account.id).one('buildings', building.id)
                .customGET('metrics', {
                interval: '1h',
                start: start,
                end: end,
                partitionId: building.calendar,
                partitionType: 'workcalendar',
                measure: 'ENERGY'
            })
                .catch(function () {
                return {
                    id: building.id,
                    start: start,
                    end: end,
                    partitions: []
                };
            });
        });
        return promises;
    };
    var getMonthlyDataForType = function (Restangular, account, buildings, date, type) {
        var minDate;
        _.each(buildings, function (building) {
            var start = getFiscalYearStartMoment(building, moment(date));
            if (!minDate || start.isBefore(minDate)) {
                minDate = start;
            }
        });
        var promises = [];
        var monthDate = moment(minDate);
        while (monthDate.isSameOrBefore(moment(date))) {
            promises.push(Restangular.one('accounts', account.id).one('portfolio')
                .customGET('insightValues', { type: type, date: moment(monthDate).valueOf() })
                .catch(function () { }));
            monthDate.add(1, 'month');
        }
        return promises;
    };
    var getFiscalYearStart = function (building, currentDate) {
        var currentMonth = currentDate.month() + 1;
        var startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
        var startYear = currentDate.year();
        if (currentMonth <= startMonth) {
            startYear--;
        }
        return getBuildingTimezoneFirstDateOfMonth(building, startYear, startMonth);
    };
    var getFiscalYearStartMoment = function (building, currentDate) {
        var result = moment(currentDate);
        var startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
        var monthDiff = startMonth - (currentDate.month() + 1);
        if (monthDiff > 0) {
            result.subtract(1, 'year');
        }
        result.add(monthDiff, 'month');
        return result;
    };
    var getBuildingTimezoneFirstDateOfMonth = function (building, year, month) {
        var yearStartString = year + "-" + (month < 10 ? 0 : '') + month + "-01T00:00:00Z";
        var offsetStart = moment(yearStartString).tz(building.timeZoneId).utcOffset();
        var start = moment(yearStartString).tz(building.timeZoneId).add(-offsetStart, 'minutes').format();
        return start;
    };
});

var aq;
(function (aq) {
    var reports;
    (function (reports) {
        var PortfolioControllerYTD = /** @class */ (function (_super) {
            __extends(PortfolioControllerYTD, _super);
            /* ngInject */
            function PortfolioControllerYTD(account, buildings, buildingGroups, date, EnergyStarApiService, utilitySpendingBudgets, utilitySpendingBudgetsPastYear, buildingTargets, buildingTargetsPastYear, buildingConsumptions, buildingSchedulePartitionedConsumptions, buildingPeakDemands, buildingSchedules, PortfolioInsightService, $filter, OptionsService, buildingPersonnels, buildingOccupancy, buildingDegreeDays, reportMonth, $timeout) {
                var _this = _super.call(this, $timeout) || this;
                _this.account = account;
                _this.buildings = buildings;
                _this.buildingGroups = buildingGroups;
                _this.date = date;
                _this.EnergyStarApiService = EnergyStarApiService;
                _this.utilitySpendingBudgets = utilitySpendingBudgets;
                _this.utilitySpendingBudgetsPastYear = utilitySpendingBudgetsPastYear;
                _this.buildingTargets = buildingTargets;
                _this.buildingTargetsPastYear = buildingTargetsPastYear;
                _this.buildingConsumptions = buildingConsumptions;
                _this.buildingSchedulePartitionedConsumptions = buildingSchedulePartitionedConsumptions;
                _this.buildingPeakDemands = buildingPeakDemands;
                _this.buildingSchedules = buildingSchedules;
                _this.PortfolioInsightService = PortfolioInsightService;
                _this.$filter = $filter;
                _this.OptionsService = OptionsService;
                _this.buildingPersonnels = buildingPersonnels;
                _this.buildingOccupancy = buildingOccupancy;
                _this.buildingDegreeDays = buildingDegreeDays;
                _this.reportMonth = reportMonth;
                _this.$timeout = $timeout;
                _this.week = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
                _this.getBuildingTimezoneFirstDateOfMonth = function (building, year, month) {
                    var yearStartString = year + "-" + (month < 10 ? 0 : '') + month + "-01T00:00:00Z";
                    var offsetStart = moment(yearStartString).tz(building.timeZoneId).utcOffset();
                    var start = moment(yearStartString).tz(building.timeZoneId).add(-offsetStart, 'minutes').format();
                    return start;
                };
                _this.personas = {
                    'ASSET_MANAGER': 'Asset Manager',
                    'PROPERTY_MANAGER': 'Property Manager',
                    'BUILDING_ENGINEER': 'Building Engineer'
                };
                var unitKWH = OptionsService.getUnitByEnumName('KWH');
                var unitKW = OptionsService.getUnitByEnumName('KW');
                _this.buildingBudgetActual = _this.processBuildingDataBudget(buildings, _this.utilitySpendingBudgets, _this.utilitySpendingBudgetsPastYear);
                _this.buildingConsumptionActuals = _this.processBuildingData(buildings, _this.buildingConsumptions, date, 'sum', unitKWH);
                _this.buildingPeakDemandsActuals = _this.processBuildingData(buildings, _this.buildingPeakDemands, date, 'max', unitKW);
                _this.peakVsOffPeak = _this.processScheduleConsumptions(unitKWH, unitKW);
                _this.currencyUnit = _this.OptionsService.currencyUnit();
                _this.consumptionUnit = _this.OptionsService.getUnitByEnumName('KWH').unit;
                _this.peakDemandUnit = _this.OptionsService.getUnitByEnumName('KW').unit;
                _this.currentYear = moment(_this.date).format('YYYY');
                var formattedDate = moment(_this.date).format('YYYY-MM-DD');
                var accountDate = moment(formattedDate + 'T00:00:00Z').tz(account.timeZoneId);
                accountDate.subtract(accountDate.utcOffset(), 'minute');
                var currentMonth = moment(accountDate).month() + 1;
                var isMonthInProgress = accountDate.date() != 1;
                if (isMonthInProgress) {
                    currentMonth++;
                }
                var startDate = _this.getGlobalReportStartDate();
                accountDate.subtract(1, 'second');
                _this.reportDateView = startDate.format('M/D/YY') + " - " + accountDate.format('M/D/YY');
                _this.reportName = _this.account.accountName + " YTD Summary Report through " + accountDate.format('MMM D, YYYY');
                _this.data = [];
                _this.groupData = [];
                _.each(buildings, function (building) {
                    // Check to see if buildingGroupItem has already been created; if not, create a new building group item.
                    var buildingGroupItem = _.find(_this.groupData, function (group) { return building.buildingGroup != null && group.groupId === building.buildingGroup
                        || (building.shared || building.buildingGroup === null) && group.groupId === 0; });
                    // if no buildingGroupItem is returned, create a new one
                    if (!buildingGroupItem) {
                        var buildingGroup = _.find(_this.buildingGroups, function (bg) { return bg.id === building.buildingGroup; });
                        buildingGroupItem = {
                            groupId: buildingGroup ? buildingGroup.id : 0,
                            groupName: buildingGroup ? buildingGroup.name : 'No Group',
                            groupBuildings: [],
                            summary: _this.getEmptyDataSummary()
                        };
                        if (buildingGroup && buildingGroup.isIncomplete) {
                            buildingGroupItem.summary.isIncomplete = true;
                        }
                        _this.groupData.push(buildingGroupItem);
                    }
                    var budgetTargets = _this.getTargets(building, 'BUDGET');
                    var consumptionTargets = _this.getTargets(building, 'CONSUMPTION');
                    var peakDemandTargets = _this.getTargets(building, 'PEAK_DEMAND');
                    var consumptionActual = _.find(_this.buildingConsumptionActuals, function (actual) { return actual.buildingId == building.id; });
                    var budgetActual = _.find(_this.buildingBudgetActual, function (actual) { return actual.buildingId == building.id; });
                    var peakDemandActual = _.find(_this.buildingPeakDemandsActuals, function (actual) { return actual.buildingId == building.id; });
                    var buildingReportItem = {
                        building: _this.getBuildingViewItem(building),
                        pastMonthDataItems: [],
                        futureMonthDataItems: [],
                        summary: null
                    };
                    _this.orderedMonths = _this.PortfolioInsightService.getOrderedMonths(building, currentMonth);
                    var monthlyBudgetTargets = budgetTargets ? _this.PortfolioInsightService.getMonthlyTargetsAggregate(budgetTargets) : {};
                    var monthlyConsumptionTargets = consumptionTargets ? _this.PortfolioInsightService.getMonthlyTargetsAggregate(consumptionTargets) : {};
                    var monthlyPeakDemandTargets = peakDemandTargets ? _this.PortfolioInsightService.getMonthlyTargetsAggregate(peakDemandTargets) : {};
                    var buildingPeakOffPeakData = _.find(_this.peakVsOffPeak, function (data) { return data.buildingId == building.id; });
                    var summary = _this.getEmptyBuildingDataSummary();
                    if (building.size) {
                        summary.sqft = building.size;
                    }
                    if (buildingPeakOffPeakData) {
                        summary.pieData.data = buildingPeakOffPeakData.pieData;
                    }
                    if (!building.dataCommissionDate) {
                        summary.hasNotCommissionedBuilding = true;
                    }
                    var occupancy = _this.buildingOccupancy[building.id] ? _this.buildingOccupancy[building.id] : null;
                    var orderedMonthsWithDate = [];
                    var currentOccupancy = '';
                    _.each(_this.orderedMonths, function (monthItem) {
                        var item = angular.copy(monthItem);
                        var monthOffset = orderedMonthsWithDate.length;
                        var normalizedDate = moment(moment(startDate).add(monthOffset, 'month').format('YYYY-MM-DD'));
                        angular.extend(item, { date: normalizedDate });
                        orderedMonthsWithDate.push(item);
                    });
                    _this.getOccupancyForMonths(orderedMonthsWithDate, occupancy);
                    var buildingDegreeDaysData = _.find(_this.buildingDegreeDays, function (item) { return item.buildingId == building.id; });
                    _.each(_this.orderedMonths, function (monthItem, index) {
                        var item = {
                            buildingId: building.id,
                            month: monthItem.month,
                            isCounted: monthItem.isCounted,
                            monthDisplay: moment().month(monthItem.month - 1).format('MMM'),
                            budget: _this.getEmptyReportItem(),
                            consumption: _this.getEmptyReportItem(),
                            peakDemand: _this.getEmptyReportItem(),
                            occupancy: orderedMonthsWithDate[index].occupancy ? orderedMonthsWithDate[index].occupancy + '%' : '-',
                            degreeDays: buildingDegreeDaysData && monthItem.isCounted ? _this.getDegreeDays(buildingDegreeDaysData, index) : { hdd: null, cdd: null }
                        };
                        if (item.isCounted) {
                            currentOccupancy = item.occupancy;
                        }
                        var month = monthItem.month < 10 ? '0' + monthItem.month : monthItem.month.toString();
                        item.budget.actual = budgetActual.budget[month] ? budgetActual.budget[month] : 0;
                        item.budget.target = monthlyBudgetTargets[month] ? monthlyBudgetTargets[month] : 0;
                        item.consumption.actual = consumptionActual.monthData[month] ? consumptionActual.monthData[month] : 0;
                        item.consumption.target = monthlyConsumptionTargets[month] ? monthlyConsumptionTargets[month] : 0;
                        item.peakDemand.actual = peakDemandActual.monthData[month] ? peakDemandActual.monthData[month] : 0;
                        item.peakDemand.target = monthlyPeakDemandTargets[month] ? monthlyPeakDemandTargets[month] : 0;
                        _this.processReportItem(item.budget);
                        _this.processReportItem(item.consumption);
                        _this.processReportItem(item.peakDemand);
                        if (monthItem.isCounted) {
                            buildingReportItem.pastMonthDataItems.push(item);
                        }
                        else {
                            buildingReportItem.futureMonthDataItems.push(item);
                        }
                        if (item.isCounted) {
                            if (!item.budget.actual || !item.consumption.actual || !item.peakDemand.actual ||
                                !item.budget.target || !item.consumption.target || !item.peakDemand.target) {
                                summary.isDataMissing = true;
                                buildingGroupItem.summary.isDataMissing = true;
                            }
                        }
                        summary.budget.actual += item.isCounted && item.budget.actual ? item.budget.actual : 0;
                        summary.budget.target += item.isCounted && item.budget.target ? item.budget.target : 0;
                        summary.consumption.actual += item.isCounted && item.consumption.actual ? item.consumption.actual : 0;
                        summary.consumption.target += item.isCounted && item.consumption.target ? item.consumption.target : 0;
                        summary.peakDemand.actual += item.isCounted && item.peakDemand.actual > item.peakDemand.target ? 1 : 0;
                        summary.peakDemand.target += item.isCounted ? 1 : 0;
                        summary.degreeDays.hdd += item.degreeDays.hdd ? item.degreeDays.hdd : 0;
                        summary.degreeDays.cdd += item.degreeDays.cdd ? item.degreeDays.cdd : 0;
                    });
                    summary.occupancy = currentOccupancy;
                    _this.processSummaryItemBudget(summary.budget);
                    _this.processSummaryItemConsumption(summary.consumption);
                    switch (summary.peakDemand.actual) {
                        case 0:
                            summary.peakDemand.color = _this.getColor(95);
                            break;
                        case 1:
                            summary.peakDemand.color = _this.getColor(99);
                            break;
                        default:
                            summary.peakDemand.color = _this.getColor(101);
                            break;
                    }
                    summary.peakDemand.description = _this.getPeakDemandDescription(summary.peakDemand);
                    buildingReportItem.summary = summary;
                    var numberOfMonths = buildingReportItem.pastMonthDataItems.length;
                    if (buildingReportItem.building.sqft > 0 && numberOfMonths > 0) {
                        var budget = buildingReportItem.summary.budget.actual;
                        var consumption = buildingReportItem.summary.consumption.actual;
                        var sqft = buildingReportItem.building.sqft;
                        buildingReportItem.summary.sqftConsumptionProjection = consumption * 12 / numberOfMonths / sqft;
                        buildingReportItem.summary.sqftBudgetProjection = budget * 12 / numberOfMonths / sqft;
                        buildingReportItem.summary.sqft = sqft;
                    }
                    _this.data.push(buildingReportItem);
                    _this.sumUpBuildingGroupItem(buildingGroupItem, buildingReportItem);
                    buildingGroupItem.groupBuildings.push(buildingReportItem);
                });
                _this.summaryGroupData = {
                    budget: _this.getEmptySummaryItem(),
                    consumption: _this.getEmptySummaryItem(),
                    peakDemand: _this.getEmptySummaryItem(),
                    sqftBudgetProjection: 0,
                    sqftConsumptionProjection: 0,
                    sqft: 0,
                    budgetSqft: 0,
                    consumptionSqft: 0,
                    isIncomplete: false,
                    isDataMissing: false,
                    hasNotCommissionedBuilding: false
                };
                var ts = _this.summaryGroupData;
                _.each(_this.groupData, function (groupItem) {
                    var gs = groupItem.summary;
                    _this.processSummaryItemBudget(gs.budget);
                    _this.processSummaryItemConsumption(gs.consumption);
                    gs.peakDemand.description = _this.getPeakDemandDescription(gs.peakDemand);
                    if (gs.sqftBudgetProjection > 0) {
                        ts.sqftBudgetProjection = (ts.sqftBudgetProjection * ts.budgetSqft + gs.sqftBudgetProjection * gs.budgetSqft) / (gs.budgetSqft + ts.budgetSqft);
                        ts.budgetSqft += gs.budgetSqft;
                    }
                    if (gs.sqftConsumptionProjection > 0) {
                        ts.sqftConsumptionProjection = (ts.sqftConsumptionProjection * ts.consumptionSqft + gs.sqftConsumptionProjection * gs.consumptionSqft) / (gs.consumptionSqft + ts.consumptionSqft);
                        ts.consumptionSqft += gs.consumptionSqft;
                    }
                    ts.sqft += gs.sqft;
                    ts.budget.actual += gs.budget.actual;
                    ts.budget.target += gs.budget.target;
                    ts.consumption.actual += gs.consumption.actual;
                    ts.consumption.target += gs.consumption.target;
                    if (gs.isIncomplete) {
                        ts.isIncomplete = true;
                    }
                    if (gs.hasNotCommissionedBuilding) {
                        ts.hasNotCommissionedBuilding = true;
                    }
                });
                _this.processReportItem(_this.summaryGroupData.budget);
                _this.processReportItem(_this.summaryGroupData.consumption);
                _this.notifyDocumentReady();
                return _this;
            }
            PortfolioControllerYTD.prototype.getTargets = function (building, targetType) {
                var measureTargets = this.buildingTargets[building.id]
                    ? _.filter(this.buildingTargets[building.id], function (target) { return target.type == targetType; })
                    : [];
                var queryDate = moment(this.date);
                var fiscalYearStart = this.getFiscalYearStart(building, queryDate);
                var isPastDataRequired = moment(fiscalYearStart).year() < queryDate.year() && queryDate.dayOfYear() != 1;
                if (isPastDataRequired) {
                    var measureTargetsPastYear = this.buildingTargetsPastYear[building.id]
                        ? _.filter(this.buildingTargetsPastYear[building.id], function (target) { return target.type == targetType; })
                        : [];
                    // for current year, clear entries for months after fiscal start
                    _.each(measureTargets, function (target) {
                        if (!target) {
                            return;
                        }
                        _.each(target.targetItems, function (item) {
                            var month = parseInt(item.startDate.substring(5, 7));
                            if (month >= building.fiscalStartMonth) {
                                item.value = 0;
                            }
                        });
                    });
                    // for past year, clear entries for months before fiscal start
                    _.each(measureTargetsPastYear, function (target) {
                        if (!target) {
                            return;
                        }
                        _.each(target.targetItems, function (item) {
                            var month = parseInt(item.startDate.substring(5, 7));
                            if (month < building.fiscalStartMonth) {
                                item.value = 0;
                            }
                        });
                    });
                    return measureTargets.concat(measureTargetsPastYear);
                }
                else
                    return measureTargets;
            };
            PortfolioControllerYTD.prototype.getDegreeDays = function (buildingData, index) {
                var data = buildingData.degreeDays;
                var hdd = data && data.hdd && data.hdd.values ? data.hdd.values[index] : null;
                if (hdd) {
                    hdd = Math.round(hdd);
                }
                var cdd = data && data.cdd && data.cdd.values ? data.cdd.values[index] : null;
                if (cdd) {
                    cdd = Math.round(cdd);
                }
                return { hdd: hdd, cdd: cdd };
            };
            PortfolioControllerYTD.prototype.getPeakDemandDescription = function (peakDemand) {
                return peakDemand.actual + " of " + peakDemand.target + " peaks missed";
            };
            PortfolioControllerYTD.prototype.getOccupancyForMonths = function (monthItems, occupancy) {
                if (!occupancy || !occupancy.length) {
                    return;
                }
                var sortedOccupancy = _.sortBy(occupancy, 'startDate');
                var index = 0;
                var length = sortedOccupancy.length;
                var current = sortedOccupancy[0];
                _.each(monthItems, function (monthItem, i) {
                    if (monthItem.date.isBefore(moment(current.startDate).startOf('month'))) {
                        monthItem.occupancy = i > 0 ? monthItems[i - 1].occupancy : null;
                        return;
                    }
                    while (index < length - 1 && moment(current.startDate).startOf('month').isSameOrBefore(monthItem.date)) {
                        monthItem.occupancy = current.occupancyPercent;
                        current = sortedOccupancy[++index];
                    }
                    if (index == length - 1 && moment(current.startDate).startOf('month').isSameOrBefore(monthItem.date)) {
                        monthItem.occupancy = current.occupancyPercent;
                    }
                });
            };
            PortfolioControllerYTD.prototype.getGlobalReportStartDate = function () {
                var _this = this;
                var queryDate = moment(this.date);
                var minDate = null;
                _.each(this.buildings, function (building) {
                    var fiscalYearStart = _this.getFiscalYearStart(building, queryDate);
                    var tsFiscalYearStart = moment(fiscalYearStart);
                    if (minDate == null || tsFiscalYearStart.isBefore(minDate)) {
                        minDate = tsFiscalYearStart;
                    }
                });
                return minDate;
            };
            PortfolioControllerYTD.prototype.getBuildingViewItem = function (building) {
                var buildingState = building.state ? building.state.replace('District of Columbia', '') : '';
                var buildingPersonnel = this.getBuildingPersonnel(building);
                var energyStarScore = null;
                Promise.resolve(this.EnergyStarApiService.getEnergyStarScoreForBuilding(this.account.id, building.id))
                    .then(function (resp) {
                    energyStarScore = resp.score;
                });
                return {
                    id: building.id,
                    name: building.name,
                    imageUrl: building.imageUrl,
                    streetAddress: building.address,
                    cityAddress: building.city + ", " + buildingState + " " + building.zipCode,
                    energyStarScore: energyStarScore,
                    fiscalStartMonth: building.fiscalStartMonth,
                    sqft: building.size ? building.size : 0,
                    personnel: buildingPersonnel,
                    dataCommissionDate: building.dataCommissionDate
                };
            };
            PortfolioControllerYTD.prototype.getBuildingPersonnel = function (building) {
                var data = _.find(this.buildingPersonnels, { buildingId: building.id });
                if (data && data.personnel && data.personnel.length > 0) {
                    _.each(data.personnel, function (item) {
                        if (!item.email) {
                            return;
                        }
                        var emailSplit = item.email.split('@');
                        if (emailSplit.length != 2) {
                            return;
                        }
                        item.emailUsername = emailSplit[0];
                        item.emailDomain = '@' + emailSplit[1];
                    });
                    var sortedPersonnel_1 = [];
                    _.each(this.personas, function (persona, key) {
                        var nextPerson = _.find(data.personnel, function (item) { return item.persona == key; });
                        if (nextPerson) {
                            sortedPersonnel_1.push(nextPerson);
                        }
                        else {
                            sortedPersonnel_1.push({
                                persona: key,
                                name: '-'
                            });
                        }
                    });
                    return sortedPersonnel_1;
                }
                else {
                    return [];
                }
            };
            PortfolioControllerYTD.prototype.processScheduleConsumptions = function (unitEnergy, unitPower) {
                var _this = this;
                var result = _.map(this.buildingSchedulePartitionedConsumptions, function (data) {
                    var startValue = moment(data.start).valueOf();
                    var endValue = moment(data.end).valueOf();
                    var building = _.find(_this.buildings, function (b) { return b.id == data.id; });
                    var peakDemandDataMonths = _.compact(_.map(_this.buildingPeakDemands, function (bp) { return bp[building.id]; }));
                    var scheduleRules = [];
                    var buildingSchedule = _.find(_this.buildingSchedules, function (s) { return s.buildingId == building.id; });
                    var rules = buildingSchedule && buildingSchedule.schedule ? buildingSchedule.schedule.rules : [];
                    _.each(rules, function (rule) {
                        angular.extend(rule, {
                            startTimeHours: rule.timeStart ? parseInt(rule.timeStart.substr(0, 2)) : 0,
                            startTimeMinutes: rule.timeStart ? parseInt(rule.timeStart.substr(3, 2)) : 0,
                            endTimeHours: rule.timeEnd ? parseInt(rule.timeEnd.substr(0, 2)) : 23,
                            endTimeMinutes: rule.timeEnd ? parseInt(rule.timeEnd.substr(3, 2)) : 59,
                            consumption: 0,
                            monthPeaks: {},
                            avgMaxPeak: 0
                        });
                        scheduleRules.push(rule);
                    });
                    var filteredScheduleRules = scheduleRules.filter(function (sr) {
                        var ytdStart = moment(moment().startOf('year').format('MM/DD/YYYY'), 'MM/DD/YYYY');
                        var ytdEnd = moment(moment().startOf('day').format('MM/DD/YYYY'), 'MM/DD/YYYY');
                        if (sr.dateStart === null && sr.dateEnd === null && sr.timeStart === null && sr.timeEnd === null) {
                            return true;
                        }
                        return _this.isBetweenStartAndEnd(ytdStart, ytdEnd, sr.dateStart, sr.dateEnd) || (sr.timeStart !== null || sr.timeEnd !== null);
                    });
                    _.each(filteredScheduleRules, function (rule) {
                        var potentialPeakTsValues = [];
                        _.each(peakDemandDataMonths, function (peakDemandData) {
                            _.each(peakDemandData.timestamps, function (ts, i) {
                                if (ts < startValue || ts > endValue) {
                                    return;
                                }
                                var momentTs = moment(ts).tz(building.timeZoneId);
                                var weekday = _this.week[momentTs.day()];
                                if (rule.week && !_.includes(rule.week, weekday)) {
                                    return;
                                }
                                // If the rule has a date range, use that to exempt timestamps
                                if (rule.dateStart) {
                                    var ruleStart = rule.dateStart + " " + rule.startTimeHours + ":" + rule.startTimeMinutes + ":00";
                                    var startDate = moment.tz(ruleStart, 'YYYY-MM-DD hh:mm:ss', building.timeZoneId);
                                    if (momentTs.isBefore(startDate)) {
                                        return;
                                    }
                                }
                                if (rule.dateEnd) {
                                    var ruleEnd = rule.dateEnd + " " + rule.endTimeHours + ":" + rule.endTimeMinutes + ":00";
                                    var endDate = moment.tz(ruleEnd, 'YYYY-MM-DD hh:mm:ss', building.timeZoneId);
                                    if (momentTs.isAfter(endDate)) {
                                        return;
                                    }
                                }
                                potentialPeakTsValues.push({
                                    momentTs: momentTs,
                                    dateIdentifier: parseInt(momentTs.format('YYYYMMDD')),
                                    value: peakDemandData.values[i]
                                });
                            });
                        });
                        if (potentialPeakTsValues.length > 0) {
                            _this.matchDataToRule(rule, potentialPeakTsValues, 'POWER');
                        }
                    });
                    var scheduleDataPartitions = _.filter(data.partitions, function (category) {
                        return _.some(filteredScheduleRules, function (rule) { return rule.category == category.id; });
                    });
                    _.each(scheduleDataPartitions, function (category) {
                        var categoryRules = _.filter(filteredScheduleRules, function (rule) { return rule.category == category.id; });
                        var monthPeaksAgg = _.first(categoryRules).monthPeaks;
                        _.each(categoryRules, function (rule) {
                            _.each(rule.monthPeaks, function (item, key) {
                                if (!monthPeaksAgg[key] || item > monthPeaksAgg[key]) {
                                    monthPeaksAgg[key] = item;
                                }
                            });
                        });
                        category.monthPeaks = monthPeaksAgg;
                    });
                    _.each(scheduleDataPartitions, function (category) {
                        category.consumptionView = _this.$filter('toUnit')(category.values.total, unitEnergy);
                        category.avgMaxPeak = _.chain(category.monthPeaks).map().mean().value();
                        if (isNaN(category.avgMaxPeak)) {
                            category.avgMaxPeak = 0;
                        }
                        category.avgMaxPeakView = _this.$filter('toUnit')(category.avgMaxPeak, unitPower);
                    });
                    return {
                        buildingId: building.id,
                        pieData: _.map(scheduleDataPartitions, function (category) {
                            return {
                                label: category.name,
                                data: category.values.total,
                                color: category.color,
                                consumption: category.consumptionView,
                                avgMaxPeak: category.avgMaxPeakView
                            };
                        })
                    };
                });
                return result;
            };
            PortfolioControllerYTD.prototype.processBuildingDataBudget = function (buildings, data, pastData) {
                var _this = this;
                var result = [];
                var queryDate = moment(this.date);
                _.each(buildings, function (building, index) {
                    var fiscalYearStart = _this.getFiscalYearStart(building, queryDate);
                    var tsFiscalYearStart = moment(fiscalYearStart);
                    var isPastDataRequired = moment(fiscalYearStart).year() < queryDate.year();
                    var buildingData = data[index];
                    var pastBuildingData;
                    if (isPastDataRequired && pastData) {
                        pastBuildingData = pastData[index];
                    }
                    if (!buildingData && (!isPastDataRequired || !pastBuildingData)) {
                        result.push({
                            buildingId: building.id,
                            budget: {}
                        });
                        return;
                    }
                    var currentBuildingBudgetActual = {};
                    if (isPastDataRequired && pastBuildingData) {
                        _this.processActualBudgetsForCurrentBuilding(currentBuildingBudgetActual, pastBuildingData, queryDate, tsFiscalYearStart, building);
                    }
                    _this.processActualBudgetsForCurrentBuilding(currentBuildingBudgetActual, buildingData, queryDate, tsFiscalYearStart, building);
                    result.push({
                        buildingId: building.id,
                        budget: currentBuildingBudgetActual
                    });
                });
                return result;
            };
            PortfolioControllerYTD.prototype.processBuildingData = function (buildings, reponseData, date, action, unit) {
                var _this = this;
                var result = [];
                _.each(buildings, function (building) {
                    _.each(reponseData, function (monthDataItem) {
                        var buildingData = _.find(result, function (item) { return item.buildingId == building.id; });
                        if (!buildingData) {
                            buildingData = {
                                buildingId: building.id,
                                monthData: {}
                            };
                            result.push(buildingData);
                        }
                        var fiscalYearStart = moment(_this.getFiscalYearStart(building, moment(date)));
                        var responseBuildingData = monthDataItem[building.id];
                        if (!responseBuildingData) {
                            return;
                        }
                        _.each(responseBuildingData.timestamps, function (timestamp, index) {
                            var ts = moment(timestamp);
                            if (ts.isBefore(fiscalYearStart) || ts.isAfter(moment(date))) {
                                return;
                            }
                            var month = ts.tz(building.timeZoneId).format('MM');
                            if (!buildingData.monthData[month]) {
                                buildingData.monthData[month] = 0;
                            }
                            var value = responseBuildingData.values[index];
                            switch (action) {
                                case 'sum':
                                    buildingData.monthData[month] += value ? value : 0;
                                    break;
                                case 'max':
                                    if (value > buildingData.monthData[month]) {
                                        buildingData.monthData[month] = value;
                                    }
                                    break;
                                default:
                                    break;
                            }
                        });
                    });
                });
                _.each(result, function (item) {
                    _.each(item.monthData, function (value, key) {
                        item.monthData[key] = _this.$filter('toUnit')(item.monthData[key], unit);
                    });
                });
                return result;
            };
            PortfolioControllerYTD.prototype.getFiscalYearStart = function (building, currentDate) {
                var currentMonth = currentDate.month() + 1;
                var startMonth = building.fiscalStartMonth ? building.fiscalStartMonth : 1;
                var startYear = currentDate.year();
                if (currentMonth <= startMonth) {
                    startYear--;
                }
                return this.getBuildingTimezoneFirstDateOfMonth(building, startYear, startMonth);
            };
            ;
            PortfolioControllerYTD.prototype.sumUpBuildingGroupItem = function (groupItem, buildingItem) {
                var gs = groupItem.summary;
                var bs = buildingItem.summary;
                if (bs.sqft) {
                    if (bs.sqftBudgetProjection > 0) {
                        gs.sqftBudgetProjection = (gs.sqftBudgetProjection * gs.budgetSqft + bs.sqftBudgetProjection * bs.sqft) / (gs.budgetSqft + bs.sqft);
                        gs.budgetSqft += bs.sqft;
                    }
                    if (bs.sqftConsumptionProjection > 0) {
                        gs.sqftConsumptionProjection = (gs.sqftConsumptionProjection * gs.consumptionSqft + bs.sqftConsumptionProjection * bs.sqft) / (gs.consumptionSqft + bs.sqft);
                        gs.consumptionSqft += bs.sqft;
                    }
                }
                if (bs.hasNotCommissionedBuilding) {
                    gs.hasNotCommissionedBuilding = true;
                }
                gs.sqft += buildingItem.building.sqft;
                gs.budget.actual += bs.budget.actual;
                gs.budget.target += bs.budget.target;
                gs.consumption.actual += bs.consumption.actual;
                gs.consumption.target += bs.consumption.target;
                gs.peakDemand.actual += bs.peakDemand.actual;
                gs.peakDemand.target += bs.peakDemand.target;
            };
            PortfolioControllerYTD.prototype.processReportItem = function (item) {
                item.diff = item.target - item.actual;
                item.variance = item.target ? Math.abs(Math.round((item.target - item.actual) * 1000 / item.target) / 10) : 0;
                item.percentageOfGoal = item.target ? Math.round(item.actual * 1000 / item.target) / 10 : 0;
            };
            PortfolioControllerYTD.prototype.processSummaryItemBudget = function (item) {
                this.processSummaryItem(item);
                if (item && item.target) {
                    item.description = this.filterCharge(item.actual) + " of " + this.filterCharge(item.target);
                }
            };
            PortfolioControllerYTD.prototype.processSummaryItemConsumption = function (item) {
                this.processSummaryItem(item);
                if (item && item.target) {
                    item.description = this.filterConsumption(item.actual) + " of " + this.filterConsumption(item.target);
                }
            };
            PortfolioControllerYTD.prototype.processSummaryItem = function (item) {
                this.processReportItem(item);
                item.color = this.getColor(item.variance);
            };
            PortfolioControllerYTD.prototype.getColor = function (percent) {
                if (percent > 100) {
                    return '#E70F28';
                }
                else if (percent <= 100 && percent >= 98) {
                    return '#F1A81D';
                }
                else if (percent < 98) {
                    return '#7ACD46';
                }
            };
            PortfolioControllerYTD.prototype.isBetweenStartAndEnd = function (ytdStart, ytdEnd, ruleStart, ruleEnd) {
                if (ruleStart !== null && ruleEnd !== null) {
                    return (ytdStart.isSame(ruleStart) || ytdStart.isBefore(ruleStart)) &&
                        (ytdEnd.isSame(ruleEnd) || ytdEnd.isAfter(ruleEnd));
                }
                else if (ruleStart !== null) {
                    return ytdStart.isSame(ruleStart) || ytdStart.isBefore(ruleStart);
                }
                else if (ruleEnd !== null) {
                    return ytdEnd.isSame(ruleEnd) || ytdEnd.isAfter(ruleEnd);
                }
                return false;
            };
            PortfolioControllerYTD.prototype.processActualBudgetsForCurrentBuilding = function (currentBuildingBudgetActual, buildingData, queryDate, tsFiscalYearStart, building) {
                _.each(buildingData.spending.data, function (data) {
                    var ts = moment(data.localDate, 'MM/DD/YYYY');
                    var tsFormattedFiscalYearStart = moment(tsFiscalYearStart.format('MM/DD/YYYY'), 'MM/DD/YYYY');
                    if (!ts.isBefore(queryDate) || ts.isBefore(tsFormattedFiscalYearStart)) {
                        return;
                    }
                    var month = ts.format('MM');
                    if (!currentBuildingBudgetActual[month]) {
                        currentBuildingBudgetActual[month] = 0;
                    }
                    var value = data.datum.consumption || data.datum.utilityBill || 0;
                    currentBuildingBudgetActual[month] = value ? value : 0;
                });
            };
            PortfolioControllerYTD.prototype.filterCharge = function (charge) {
                return this.$filter('formatCharge')(charge, this.currencyUnit, false);
            };
            PortfolioControllerYTD.prototype.filterConsumption = function (charge) {
                return this.$filter('formatUsage')(charge, this.consumptionUnit);
            };
            PortfolioControllerYTD.prototype.getEmptyDataSummary = function () {
                var item = {
                    sqft: 0,
                    budgetSqft: 0,
                    consumptionSqft: 0,
                    sqftBudgetProjection: 0,
                    sqftConsumptionProjection: 0,
                    budget: this.getEmptySummaryItem(),
                    consumption: this.getEmptySummaryItem(),
                    peakDemand: this.getEmptySummaryItem(),
                    isIncomplete: false,
                    isDataMissing: false,
                    hasNotCommissionedBuilding: false
                };
                return item;
            };
            PortfolioControllerYTD.prototype.getEmptyBuildingDataSummary = function () {
                var item = this.getEmptyDataSummary();
                angular.extend(item, {
                    pieData: { data: null },
                    degreeDays: { hdd: 0, cdd: 0 },
                    occupancy: ''
                });
                return item;
            };
            PortfolioControllerYTD.prototype.getEmptyReportItem = function () {
                var item = {
                    actual: 0,
                    target: 0,
                    diff: 0,
                    variance: 0,
                    description: '',
                    percentageOfGoal: 0
                };
                return item;
            };
            PortfolioControllerYTD.prototype.getEmptySummaryItem = function () {
                var item = this.getEmptyReportItem();
                item.color = '';
                return item;
            };
            PortfolioControllerYTD.prototype.matchDataToRule = function (rule, tsValues, type) {
                var len = tsValues.length;
                var item = tsValues[0];
                var i = 1;
                var lastDateIdentifier = item.dateIdentifier;
                while (i < len) {
                    while (lastDateIdentifier == item.dateIdentifier && i < len && item.momentTs.hour() < rule.startTimeHours) {
                        item = tsValues[i++];
                    }
                    if (lastDateIdentifier != item.dateIdentifier) {
                        lastDateIdentifier = item.dateIdentifier;
                        continue;
                    }
                    while (lastDateIdentifier == item.dateIdentifier && i < len && item.momentTs.hour() == rule.startTimeHours && item.momentTs.minute() < rule.startTimeMinutes) {
                        item = tsValues[i++];
                    }
                    if (lastDateIdentifier != item.dateIdentifier) {
                        lastDateIdentifier = item.dateIdentifier;
                        continue;
                    }
                    while (lastDateIdentifier == item.dateIdentifier && i < len && item.momentTs.hour() < rule.endTimeHours) {
                        this.processRuleData(rule, item, type);
                        item = tsValues[i++];
                        if (lastDateIdentifier == item.dateIdentifier && i == len && item.momentTs.hour() < rule.endTimeHours) {
                            this.processRuleData(rule, item, type);
                        }
                    }
                    if (lastDateIdentifier != item.dateIdentifier) {
                        lastDateIdentifier = item.dateIdentifier;
                        continue;
                    }
                    while (lastDateIdentifier == item.dateIdentifier && i < len && item.momentTs.hour() == rule.endTimeHours && item.momentTs.minute() < rule.endTimeMinutes) {
                        this.processRuleData(rule, item, type);
                        item = tsValues[i++];
                        if (lastDateIdentifier == item.dateIdentifier && i == len && item.momentTs.hour() == rule.endTimeHours && item.momentTs.minute() < rule.endTimeMinutes) {
                            this.processRuleData(rule, item, type);
                        }
                    }
                    if (lastDateIdentifier != item.dateIdentifier) {
                        lastDateIdentifier = item.dateIdentifier;
                        continue;
                    }
                    while (lastDateIdentifier == item.dateIdentifier && i < len) {
                        item = tsValues[i++];
                    }
                    if (i < len) {
                        lastDateIdentifier = item.dateIdentifier;
                    }
                }
            };
            ;
            PortfolioControllerYTD.prototype.processRuleData = function (rule, tsValueItem, type) {
                switch (type) {
                    case 'ENERGY':
                        rule.consumption += tsValueItem.value;
                        break;
                    case 'POWER':
                        var month = tsValueItem.momentTs.format('MM');
                        if (!rule.monthPeaks[month]) {
                            rule.monthPeaks[month] = tsValueItem.value;
                        }
                        else if (rule.monthPeaks[month] < tsValueItem.value) {
                            rule.monthPeaks[month] = tsValueItem.value;
                        }
                        break;
                    default:
                        break;
                }
            };
            ;
            return PortfolioControllerYTD;
        }(reports.BaseDocraptorReportCtrl));
        reports.PortfolioControllerYTD = PortfolioControllerYTD;
        angular
            .module('aq.reports')
            .controller('PortfolioControllerYTD', PortfolioControllerYTD);
    })(reports = aq.reports || (aq.reports = {}));
})(aq || (aq = {}));

namespace aq.services {
    export class BuildingSelectorHelperService {
        constructor(
            private Restangular: restangular.IService,
            private RestangularV3: restangular.IService
        ) {
        }
        public getBuildings() {
            return this.RestangularV3.all('buildings').getList();
        }
        public getBuildingGroups(accountId) {
            return this.RestangularV3.all('building-groups').getList();
        }
        public getBuildingEnrollment(buildingId: number) {
            return this.RestangularV3
                .one('buildings', buildingId)
                .one('enrollment')
                .get();
        }
    }
    angular.module('aq.services').service('BuildingSelectorHelperService', BuildingSelectorHelperService);
}
